import { useCallback, useState, useEffect,useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {setSocialToken, getUserDetailsService, getCompany, getInputs, addInput, updateInput, updateStripe, updateCompany, uploadCompanyLogo,getAllEmployees, getSellerProductDetails, createSellerProduct, updateSellerProduct, createSellerDiscount, deleteSellerDiscount, updateSellerDiscount} from '../../services/';
import { ColorPicker } from 'material-ui-color';
import styles from './styles.module.scss';

import InstagramLogin from 'react-instagram-login';

import logoDark from "../../images/logo-dark.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import locationIcon from '../../images/route-icon.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import editIcon from '../../images/edit-icon.png';
import plusIcon from '../../images/plus-icon.png';
import employeeIconPink from '../../images/employee-icon-pink.png';
import xButton from '../../images/x-button.png';
import bulkUploadIcon from '../../images/bulk-upload-icon.png';
import noProducts from '../../images/no-products-graphic.png';
import removeIcon from '../../images/remove-icon.png';
import storeIcon from '../../images/store-design.png';
import companyLogo from '../../images/my-company-logo.png';
import shoppingIcon from '../../images/shopping-icon.png';
import hashtagIcon from '../../images/hashtag-icon.png';
import socialIcon from '../../images/social-icon.png';



export const Confirm = () => {

    const [user, setUser] = useState({});
    const [editEmployee,setEditEmployee] = useState({});
    const [products, setProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(true);
    const [loadingForm,setLoadingForm] = useState(false);
    const [newInputWindowOpen,setNewInputWindowOpen] = useState(false);
    const [editInputWindowOpen,setEditInputWindowOpen] = useState(false);
    const [token,setToken] = useState(null);
    const [colourCodeMain, updateColourCodeMain] = useState('');
    const [colourCodeSecond, updateColourCodeSecond] = useState('');
    const [colourCodeButton, updateColourCodeButton] = useState('');
    const [newInputName, setNewInputName] = useState('');
    const [newInputType, setNewInputType] = useState('Text');
    const [newInputScreen, setNewInputScreen] = useState('');
    const [newInputMandatory, setNewInputMandatory] = useState(false);
    const [editInputName, setEditInputName] = useState('');
    const [editInputType, setEditInputType] = useState('Text');
    const [editInputScreen, setEditInputScreen] = useState('');
    const [editInputId, setEditInputId] = useState(null);
    const [editInputMandatory, setEditInputMandatory] = useState(false);
    const [types, setTypes] = useState([]);
    const [screens, setScreens] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [socialLoginOpen, setSocialLoginOpen] = useState(false);
    const [socialLogin, setSocialLogin] = useState();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [stripeFormOpen,setStripeFormOpen] = useState(false);
    const [stripePublic,setStripePublic] = useState('');
    const [message,setMessage] = useState('');
    const [stripe,setStripe] = useState('');




  const ITEMS_PER_PAGE = 25;

  const loadDetails = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    const profile = await cookies.get('profile');

    if(!token){
      navigate('/');
    }else{
      setToken(token);

      const code = searchParams.get('code');

      if(code && code.length > 0){
        //Send code to backend
        let socialRes = await setSocialToken({token,profile,code:code.replace('#',''),social:'piToken',tokenSent:'ABC'});

        if(!socialRes.data || !socialRes.data.success){
          alert("Error logging you into your social media account.");
          setMessage('Please try again.');
        }else{
          setMessage('Login Successful.');
        }
        setLoading(false);
      }


    }

  }

  useEffect(()=>{
    loadDetails();
  },[]);

  const logout = async(social)=>{

  }

  const login = async(social)=>{
    //await setSocialLogin(social);
    //await setSocialLoginOpen(true);
    window.open(social.url);
  }

  const saveSettings = async()=>{
    await setLoading(true);
    const updateData = await updateCompany({mainColour:colourCodeMain.hex?"#"+colourCodeMain.hex:colourCodeMain,secondaryColour:colourCodeSecond.hex?"#"+colourCodeSecond.hex:colourCodeSecond,buttonsColour:colourCodeButton.hex?"#"+colourCodeButton.hex:colourCodeButton,token});

    if(!updateData.data.success){
      alert("Error saving settings.");
      console.log(updateData);
    }

    setLoading(false);
  }

  const saveInput = async()=>{
    await setLoading(true);
    let screenId = null;
    console.log(newInputScreen);
    for(let index = 0;index < screens.length;index++){
      if(newInputScreen == screens[index].screenName) screenId = screens[index].id;
    }

    if(!screenId){
      alert("Please choose a screen to place the input on!");
      setLoading(false);
      return;
    }

    const updateData = await addInput({name:newInputName,type:newInputType,page:parseInt(screenId),mandatory:newInputMandatory?1:0,token});

    if(!updateData.data.success){
      alert("Error: "+updateData.data.error);
      console.log(updateData);
    }else{
      setNewInputWindowOpen(false);
      setInputs(updateData.data.inputs);
    }

    setLoading(false);
  }

  const renderSocialLoginWindow = ()=>{
    if(socialLoginOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setSocialLoginOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={socialIcon}/>
                <div className={styles.discountsRecentBookingsTitleText}>Social Accoutn Login</div>
              </div>
            </div>
            {socialLogin.button}
          </div>
        </div>
      )

    }
  }

  const updateChosenInput = async()=>{
    await setLoading(true);
    let screenId = null;
    for(let index = 0;index < screens.length;index++){
      if(editInputScreen == screens[index].screenName) screenId = screens[index].id;
    }

    if(!screenId){
      alert("Please choose a screen to place the input on!");
      setLoading(false);
      return;
    }

    const updateData = await updateInput({id:editInputId,name:editInputName,type:editInputType,page:parseInt(screenId),mandatory:editInputMandatory?1:0,token});

    if(!updateData.data.success){
      alert("Error: "+updateData.data.error);
    }else{
      setEditInputWindowOpen(false);
      setInputs(updateData.data.inputs);
    }

    setLoading(false);
  }

  const renderEditProductWindow = ()=>{
    if(editInputWindowOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setEditInputWindowOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={productIconPink}/>
                <div className={styles.discountsRecentBookingsTitleText}>Edit Customer Booking Form Input</div>
              </div>
            </div>
            <div className={styles.discountsForm}>
              <TextInput placeholder="Is this input mandatory?*" type="form-checkbox" onChange={(event)=>{setEditInputMandatory(!newInputMandatory)}} initialValue={editInputMandatory}/>
              <br/>
              <TextInput placeholder="Input Name/ Checkbox Text*" type="form-text" onChange={(event)=>{setEditInputName(event.target.value);}} initialValue={editInputName}/>
              <TextInput placeholder="Input Type*" type="dropdown" onChange={(event)=>{setEditInputType(event)}} initialValue={editInputType} options={['Text','Number','Checkbox','Email']}/>
              <TextInput placeholder="What Screen Is This Input For?*" type="dropdown" onChange={(event)=>{setEditInputScreen(event)}} initialValue={editInputScreen} options={screens.map((screen,screenIndex)=>{return screen.screenName})}/>

              <br/><br/>
              <div className={styles.discountsFormNote}>This input will be added to your website on the desired page of your booking form.</div>

              <div className={styles.formButtonContainer}>
                {renderFormEditInputsButton()}
              </div>
            </div>
          </div>
        </div>
      )

    }
  }

  const renderStripeWindow = ()=>{
    if(stripeFormOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setStripeFormOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={productIconPink}/>
                <div className={styles.discountsRecentBookingsTitleText}>Update My Stripe Details</div>
              </div>
            </div>
            <div className={styles.discountsForm}>
              <TextInput placeholder="Stripe Live Public Key*" type="form-text" onChange={(event)=>{setStripePublic(event.target.value);}} initialValue={stripePublic}/>
              <TextInput placeholder="Stripe Live Private Key*" type="form-text" onChange={(event)=>{setStripe(event.target.value);}} initialValue={stripe}/>

              <br/><br/>
              <div className={styles.discountsFormNote}>Your Stripe details will be securely stored.</div>

              <div className={styles.formButtonContainer}>
                {renderFormEditStripeButton()}
              </div>
            </div>
          </div>
        </div>
      )

    }
  }


  const renderFormEditInputsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateChosenInput();}} />
    )
  }

  const renderFormInputsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveInput();}} />
    )
  }

  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveSettings();}} />
    )
  }

  const saveStripe = async()=>{
    await setLoadingForm(true);

    const saveDetails = await updateStripe({token,stripe,stripePublic});
    if(saveDetails.data.success){
      setStripe('');
      setStripePublic('');
      setStripeFormOpen(false);
    }else{
      alert("Error: "+saveDetails.data.error);
    }

    setLoadingForm(false);

  }

  const renderFormEditStripeButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveStripe();}} />
    )
  }

  const uploadFile = async(event)=>{
    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const logoResult = await uploadCompanyLogo(formData,token);
    if(logoResult.data.success){
      setImageUrl(logoResult.data.logoUrl);
    }else{
      alert("Error uploading. Please try again.");
    }
    event.target.value = null;
  }

  const renderDashboard = ()=>{

    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={socialIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>My Social Accounts</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>
        </div>
      </div>
    )

  }

  const renderCustomInputs = ()=>{
    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={hashtagIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Followed Hashtags</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>

        </div>
      </div>
    )
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={32} color={"#191919"} />
        </div>
      </div>
    )
  }

  const renderLocations = ()=>{
    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={locationIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Locations</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>

        </div>
      </div>
    )
  }

  const renderIntegrations = ()=>{
    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={storeIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Integrations</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>

        </div>
      </div>
    )
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={32} color={"#191919"} />
        </div>
      </div>
    )
  }


  if(token){
    return(
      <div className="global-container">
        <div style={{fontFamily: "Montserrat",fontSize:"1.6rem",position:"relative",left:"10%",textAlign:"center",width:"80vw", height:"46%", marginTop:"30vh"}}>
          <img style={{marginBottom:"6vh",width:"12vw",borderRadius:"200px"}} src={(message == 'Login Successful.')? require('../../images/check-icon.png'):require('../../images/x-icon.png')}/>
          <br/>
          {message}
        </div>
      </div>
    )
}


}
