import { useRoutes } from "react-router-dom";
import { Auth } from "../views/auth";
import { Dashboard } from "../views/dashboard";
import { View } from "../views/view";
import { Settings } from "../views/settings";
import { Confirm } from "../views/confirm";
import { Referrals } from "../views/referrals";
import { Contact } from "../views/contact";
//import { NotFound } from "../views/notFound";

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
  {
    element: <Auth />,
    path: "/",
  },
   {
    element: <Dashboard />,
    path: "dashboard",
  },
{
 element: <Settings />,
 path: "store-design",
},
{
 element: <View />,
 path: "view",
},
{
 element: <Referrals />,
 path: "referrals",
},
{
 element: <Contact />,
 path: "contacts",
},
{
 element: <Confirm />,
 path: "confirm",
}
  /* { path: "*", element: <NotFound /> }, */
];
