import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {getUserDetailsService ,getRecentBookings, getSellerProductDetails, getRecentCustomers, createOrder} from '../services/';

const getUserDetails = createAsyncThunk(
  'users/getUserDetails',
  async (data, thunkAPI) => {
    const user = await getUserDetailsService(data.token,{});
    return user.data;
  }
)

export const counterSlice = createSlice({
  name: 'authSlice',
  initialState: {
    user:{},
    recentCustomers:[],
    profile:null
  },
  reducers: {
    setProfile: (state,{ payload }) => {
      state.profile = payload.profile
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.user = action.payload
    })

  },
})

// Action creators are generated for each case reducer function
export const { setProfile } = counterSlice.actions
export default counterSlice.reducer
