import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import TimePicker from "rc-time-picker";
import {getSolicitorsBySearch, getMedicsBySearch, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder, uploadCompanyLogo} from '../../services/';
import styles from './styles.module.scss';

import logoDark from "../../images/logistible-commerce.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

export const Dashboard = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [error,setError] = useState('');
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [orderCount,setOrderCount] = useState(0);
  const [resultsCount,setResultsCount] = useState(0);
  const [inStoreCount,setInStoreCount] = useState(0);
  const [avgCustomer,setAvgCustomer] = useState(0);
  const [salesCount,setSalesCount] = useState(0);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [newOrderOpen,setNewOrderOpen] = useState(false);
  const [customerName,setCustomerName] = useState('');
  const [customerEmail,setCustomerEmail] = useState('');
  const [customerPhone,setCustomerPhone] = useState('');
  const [customerAddress,setCustomerAddress] = useState('');
  const [customerPostcode,setCustomerPostcode] = useState('');
  const [orderProducts,setOrderProducts] = useState([]);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const doctorRefEdit = useRef(null);
  const solicitorRefEdit = useRef(null);
  const medicEmailRef = useRef(null);
  const medicTypeRef = useRef(null);
  const clinicLocationRef = useRef(null);
  const clinicAddressRef = useRef(null);
  const solicitorPrimaryEmailRef = useRef(null);
  const solicitorSecondaryEmailRef = useRef(null);
  const solicitorReferenceRef = useRef(null);
  const appointmentDateRef = useRef(null);
  const appointmentTimeRef = useRef(null);
  const [sendEmails, setSendEmails] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [dropdownDate, setDropdownDate] = useState('');
  const [dropdownTime, setDropdownTime] = useState('');
  const navigate = useNavigate();

  //Appointment Details
  const [piab, setPiab] = useState('')
  const [claimantName, setClaimantName] = useState('')
  const [claimantPhone, setClaimantPhone] = useState('')
  const [claimantAddress, setClaimantAddress] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('12:00')
  const [claimantEmail, setClaimantEmail] = useState('')
  const [claimantDOB, setClaimantDOB] = useState('')
  const [incidentDate, setIncidentDate] = useState('')
  const [ldta, setLdta] = useState('')
  const [solicitorName, setSolicitorName] = useState('')
  const [solicitorEmail, setSolicitorEmail] = useState('')
  const [specialNoteToSolicitor, setSpecialNoteToSolicitor] = useState('')
  const [specialNoteToDoctor, setSpecialNoteToDoctor] = useState('')
  const [medicType, setMedicType] = useState('')
  const [solicitorReference, setSolicitorReference] = useState('')
  const [backupSolicitorEmail, setBackupSolicitorEmail] = useState('')
  const [clinicLocation, setClinicLocation] = useState('')
  const [clinicAddress, setClinicAddress] = useState('')
  const [expertEmail, setExpertEmail] = useState('')
  const [medicalExpert, setMedicalExpert] = useState(null)
  const [medicId, setMedicId] = useState(null)
  const [expertType, setExpertType] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [doctors, setDoctors] = useState([])
  const [solicitors, setSolicitors] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])

  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(true);

  const createAppointment = async()=>{
    setLoading(true);
    let formData = new FormData();
    for(let index = 0; index < files.length;index++){
      formData.append('file'+(index+1), files[index])
    }

    formData.append('piab', piab)
    formData.append('claimantName', claimantName)
    formData.append('claimantPhone', claimantPhone)
    formData.append('claimantAddress', claimantAddress)
    formData.append('appointmentTime', appointmentTime)
    formData.append('claimantEmail', claimantEmail)
    formData.append('claimantDOB', claimantDOB)
    formData.append('incidentDate', incidentDate)
    formData.append('ldta', ldta)
    formData.append('solicitorName', solicitorName)
    formData.append('solicitorEmail', solicitorEmail)
    formData.append('specialNoteToSolicitor', specialNoteToSolicitor)
    formData.append('specialNoteToDoctor', specialNoteToDoctor)
    formData.append('medicType', medicType)
    formData.append('solicitorReference', solicitorReference)
    formData.append('backupSolicitorEmail', backupSolicitorEmail)
    formData.append('clinicLocation', clinicLocation)
    formData.append('clinicAddress', clinicAddress)
    formData.append('expertEmail', expertEmail)
    formData.append('medicalExpert', medicalExpert)
    formData.append('expertType', expertType)
    formData.append('appointmentDate', appointmentDate)
    formData.append('sendEmails', sendEmails)
    formData.append('medicId', medicId)

    console.log(formData.toString());
    const uploadResult = await uploadCompanyLogo(formData,token);
    if(uploadResult.data.success){
      await setAvailableTimes(availableTimes.filter((time)=>{ return (time.date != appointmentDate || time.time != appointmentTime)}))
      setPiab('')
      setClaimantName('')
      setClaimantPhone('')
      setClaimantAddress('')
      //setAppointmentTime('')
      setClaimantEmail('')
      setClaimantDOB('')
      setIncidentDate('')
      setLdta('')
      setSolicitorName('')
      setSolicitorEmail('')
      setSpecialNoteToSolicitor('')
      setSpecialNoteToDoctor('')
      //setMedicType('')
      setSolicitorReference('')
      setBackupSolicitorEmail('')
      setFiles([])
      //setClinicLocation('')
      //setClinicAddress('')
      //setExpertEmail('')
      //setMedicalExpert('')
      //setExpertType('')
      if(uploadResult.data.emailsError){
        alert('Appointment Saved. Some emails not sent: '+uploadResult.data.emailsErrorString);
      }else{
        alert('Appointment Saved.');
      }

    }else{
      alert("Error uploading. Please try again.");
    }
    await setLoading(false);
    await doctorRefEdit.current.setTextboxValue(medicalExpert)
  }

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log('TOK'+token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }


  const searchDoctors = async(search, token)=>{
    const doctors = await getMedicsBySearch({token,search});
    if(doctors.data.success){
      setDoctors(doctors.data.medics.map((doctor,doctorIndex)=>{
        return {...doctor}
      }));
    }
  }

  const searchSolicitors = async(search, token)=>{
    if(search.length == 0) return;
    const solicitors = await getSolicitorsBySearch({token,search});
    if(solicitors.data.success){
      setSolicitors(solicitors.data.solicitors.map((solicitor,solicitorIndex)=>{
        return {...solicitor}
      }));
    }
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Home"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.NAME+" "+user.SURNAME || "Aalto User"}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px"}} className={"global-dashboard-row-card-white"}>

                  <div className={styles.cardHeader}>New Case</div>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="PIAB reference number*" type="form-text" onChange={(value)=>{setPiab(value)}} initialValue={piab}/>
                      <TextInput placeholder="Claimant Name*" type="form-text" onChange={(value)=>{setClaimantName(value)}} initialValue={claimantName}/>
                      <TextInput placeholder="Claimant Phone*" type="form-text" onChange={(value)=>{setClaimantPhone(value)}} initialValue={claimantPhone}/>
                      <TextInput placeholder="Claimant Address*" type="form-text" onChange={(value)=>{setClaimantAddress(value)}} initialValue={claimantAddress}/>
                      <TextInput placeholder="Medic Type Needed*" type="form-text" onChange={(value)=>{setMedicType(value)}} initialValue={medicType}/>

                    </div>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Claimant Email*" type="form-text" onChange={(value)=>{setClaimantEmail(value)}} initialValue={claimantEmail}/>
                      <TextInput placeholder="Claimant DOB*" type="form-text" onChange={(value)=>{setClaimantDOB(value)}} initialValue={claimantDOB}/>
                      <TextInput placeholder="Incident Date*" type="form-text" onChange={(value)=>{setIncidentDate(value)}} initialValue={incidentDate}/>
                      <TextInput placeholder="LDTA*" type="form-text" onChange={(value)=>{setLdta(value)}} initialValue={ldta}/>
                      <TextInput placeholder="Special Note to Medic*" type="form-textarea" onChange={(value)=>{setSpecialNoteToDoctor(value)}} initialValue={specialNoteToDoctor}/>
                    </div>
                  </div>
                  <br/><br/>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                    <TextInput placeholder="Solicitor" ref={solicitorRefEdit} options={solicitors} type="form-search" solicitors={true} onChangeText={(text)=>{searchSolicitors(text, token)}} onChange={async(solicitor)=>{
                        await setSolicitorEmail(solicitor.primaryEmail)
                        await setBackupSolicitorEmail(solicitor.secondaryEmail)
                        await setSolicitorName(solicitor.firmName)
                        //await setSolicitorReference(solicitor.reference || '')
                        await solicitorPrimaryEmailRef.current.setTextboxValue(solicitor.primaryEmail);
                        await solicitorSecondaryEmailRef.current.setTextboxValue(solicitor.secondaryEmail);
                        //await solicitorReferenceRef.current.setTextboxValue(solicitor.reference);
                      }} initialValue={''}/>
                    <TextInput placeholder="Named Email*" ref={solicitorPrimaryEmailRef} type="form-text" onChange={(value)=>{setSolicitorEmail(value)}} initialValue={solicitorEmail}/>
                      <TextInput placeholder="Special Note to Solicitor*" type="form-textarea" onChange={(value)=>{setSpecialNoteToSolicitor(value)}} initialValue={specialNoteToSolicitor}/>
                      <br/><br/>

                    </div>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Solicitor Reference*" ref={solicitorReferenceRef} type="form-text" onChange={(value)=>{setSolicitorReference(value)}} initialValue={solicitorReference}/>
                      <TextInput placeholder="Admin Email*" ref={solicitorSecondaryEmailRef} type="form-text" onChange={(value)=>{setBackupSolicitorEmail(value)}} initialValue={backupSolicitorEmail}/>
                    </div>
                  </div>

                  <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
                    <label for="logoInput">
                      <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Document File Upload" onClick={(event)=>{}} />
                    </label>
                  </div>
                  <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
                  {
                    files.map((file,fileIndex)=>{
                      return(
                        <div className={styles.filesList}>
                          {file.name}
                          <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                            let filesTmp = files;
                            filesTmp.splice(fileIndex,1);
                            await setFiles(filesTmp);
                            setFilesUpdated(!filesUpdated);
                          }} className={styles.removeFileButton} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px"}} className={"global-dashboard-row-card-white"}>

                  <div className={styles.cardHeader}>New Medical Apt.</div>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Medical Expert" ref={doctorRefEdit} options={doctors} type="form-search" onChangeText={(text)=>{searchDoctors(text, token)}} onChange={async(expert)=>{
                        await setMedicalExpert(expert.name)
                        await setAvailableTimes(expert.ml_med_dates || [])
                        await setClinics((expert.clinics == "[]")?[]:expert.clinics.split('[****SEP****]'))
                        await setMedicType(expert.specialty)
                        await setExpertEmail(expert.contactEmail)
                        await setClinicAddress(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                        await setClinicLocation(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                        await setMedicId(expert.id)
                        await medicTypeRef.current.setTextboxValue(expert.specialty);
                        setExpertType(expert.specialty)
                        await medicEmailRef.current.setTextboxValue(expert.contactEmail);
                        await setClinicAddress(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'');
                        await setClinicLocation(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'');
                        await clinicAddressRef.current.setTextboxValue(expert.clinics?(expert.clinics.split('[****SEP****]')[0] || ''):'')
                      }} initialValue={''}/>

                      <TextInput placeholder="Expert Type*" ref={medicTypeRef} type="form-text" onChange={(value)=>{setExpertType(value)}} initialValue={expertType}/>

                    </div>

                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Clinic Location*" type="dropdown" options={clinics} ref={clinicLocationRef} onChange={(value)=>{
                        setClinicLocation(value);
                        setClinicAddress(value)
                        clinicAddressRef.current.setTextboxValue(value);
                      }} initialValue={clinicLocation}/>
                      <TextInput placeholder="Available Dates" options={[...new Set(availableTimes.filter((time)=>{return !(time.recordId)}).filter((time)=>{return (time.location == clinicLocation)}).map((time)=>{ return time.date }))]} type="dropdown" solicitors={true} onChange={async(value)=>{
                        setDropdownDate(value)
                        setAppointmentDate(value)
                        appointmentDateRef.current.setTextboxValue(value);
                      }} initialValue={dropdownDate}/>
                      <TextInput ref={appointmentDateRef} placeholder="Appointment Date*" type="form-text" onChange={(value)=>{setAppointmentDate(value)}} initialValue={appointmentDate}/>

                    <br/><br/>

                    <TextInput placeholder="Available Times" options={availableTimes.filter((time)=>{return !(time.recordId)}).filter((time)=>{return (time.location == clinicLocation)}).filter((time)=>{return (time.date == dropdownDate)}).map((time)=>{ return time.time })} type="dropdown" solicitors={true} onChange={async(value)=>{
                      setDropdownTime(value)
                      setAppointmentTime(value)
                      appointmentTimeRef.current.setTextboxValue(value);
                    }} initialValue={dropdownTime}/>

                    <TextInput placeholder="Appointment Time*" type="form-text" options={TIMES}  onChange={(value)=>{
                      setAppointmentTime(value);
                    }} ref={appointmentTimeRef} initialValue={appointmentTime}/>
                    </div>

                  </div><br/><br/>

                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Clinic Address*" ref={clinicAddressRef} type="form-text" onChange={(value)=>{setClinicAddress(value)}} initialValue={clinicAddress}/>

                    </div>

                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Expert Email*" ref={medicEmailRef} type="form-text" onChange={(value)=>{setExpertEmail(value)}} initialValue={expertEmail}/>
                    </div>
                  </div>

                  <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
                  <span><br/>
                  <input type="checkbox" id="checkbox1" name="checkbox1" checked={sendEmails} onChange={(event)=>{setSendEmails(!sendEmails)}}></input>
                  <span style={{paddingRight:"0px"}}> Send Client, Medic, PIAB & Solicitor Emails Now?</span>
                  </span>
                  <br/><br/>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{
                      createAppointment()
                    }} />
                  </div>
                </div>
              </div>



            </div>




          </div>
        </div>
      </div>
    )
}


}
