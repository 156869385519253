import { useCallback, useState, useEffect,useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {setSocialToken, logoutFromSocial, getUserDetailsService, getCompany, getInputs, addInput, updateInput, updateStripe, updateCompany, uploadCompanyLogo,getAllEmployees, getSellerProductDetails, createSellerProduct, updateSellerProduct, createSellerDiscount, deleteSellerDiscount, updateSellerDiscount} from '../../services/';
import { ColorPicker } from 'material-ui-color';
import {continents, countries} from 'countries-list';
import styles from './styles.module.scss';

import InstagramLogin from 'react-instagram-login';

import logoDark from "../../images/logo-dark.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import locationIcon from '../../images/route-icon.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import editIcon from '../../images/edit-icon.png';
import plusIcon from '../../images/plus-icon.png';
import employeeIconPink from '../../images/employee-icon-pink.png';
import xButton from '../../images/x-button.png';
import bulkUploadIcon from '../../images/bulk-upload-icon.png';
import noProducts from '../../images/no-products-graphic.png';
import removeIcon from '../../images/remove-icon.png';
import storeIcon from '../../images/store-design.png';
import companyLogo from '../../images/my-company-logo.png';
import shoppingIcon from '../../images/shopping-icon.png';
import hashtagIcon from '../../images/hashtag-icon.png';
import socialIcon from '../../images/social-icon.png';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

export const Settings = () => {

    const [user, setUser] = useState({});
    const [editEmployee,setEditEmployee] = useState({});
    const [products, setProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(true);
    const [loadingForm,setLoadingForm] = useState(false);
    const [newInputWindowOpen,setNewInputWindowOpen] = useState(false);
    const [editInputWindowOpen,setEditInputWindowOpen] = useState(false);
    const [token,setToken] = useState(null);
    const [colourCodeMain, updateColourCodeMain] = useState('');
    const [colourCodeSecond, updateColourCodeSecond] = useState('');
    const [colourCodeButton, updateColourCodeButton] = useState('');
    const [newInputName, setNewInputName] = useState('');
    const [newInputType, setNewInputType] = useState('Text');
    const [newInputScreen, setNewInputScreen] = useState('');
    const [newInputMandatory, setNewInputMandatory] = useState(false);
    const [editInputName, setEditInputName] = useState('');
    const [editInputType, setEditInputType] = useState('Text');
    const [editInputScreen, setEditInputScreen] = useState('');
    const [editInputId, setEditInputId] = useState(null);
    const [editInputMandatory, setEditInputMandatory] = useState(false);
    const [types, setTypes] = useState([]);
    const [screens, setScreens] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [socialLoginOpen, setSocialLoginOpen] = useState(false);
    const [socialLogin, setSocialLogin] = useState();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newHashtagName, setNewHashtagName] = useState('');
    const [stripeFormOpen,setStripeFormOpen] = useState(false);
    const [stripePublic,setStripePublic] = useState('');
    const [stripe,setStripe] = useState('');
    const [userHashtags, setUserHashtags] = useState('[]');
    const [selectedContinents, setSelectedContinents] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [update, setUpdate] = useState(false);

  const SOCIALS = [
     {
      name:"Facebook & Instagram",
      ref:"fbToken",
      icon:require('../../images/fb.png'),
      src:<FacebookLogin
        appId="455363053247910"
        autoLoad={false}
        fields="name,email,picture"
        scope="pages_read_user_content,pages_read_engagement,pages_show_list,instagram_basic"
        onClick={async()=>{
          const cookies = new Cookies();
          const token = cookies.get('token');
          const profile = cookies.get('profile');
          console.log(profile);
        }}
        callback={async(response)=>{
          const cookies = new Cookies();
          const token = cookies.get('token');
          const profile = cookies.get('profile');


          if(!response['accessToken']){
            alert("Login error.");
            return;
          }

          let socialRes = await setSocialToken({token,profile,tokenSent:response['accessToken'].replace('#',''),social:'fbToken'});

          if(!socialRes.data || !socialRes.data.success){
            alert("Error logging you into your social media account.");
            console.log(socialRes.data);
          }else{
            const userDetails = await getUserDetailsService(token,{profileId:profile});
            if(userDetails.data.success){
              console.log(userDetails.data);
              setUser(userDetails.data);
            }else{
              alert("Error getting your details.");
            }
          }

        }}
        render={renderProps => (
            <div className={styles.socialOptionButtonLogin} onClick={renderProps.onClick}>Log In</div>
          )}
         />,
    },
    /*{
      name:"Instagram For Business",
      ref:"igToken",
      icon:require('../../images/ig.png'),
      src:<FacebookLogin
        appId="455363053247910"
        autoLoad={true}
        fields="name,email,picture"
        scope="instagram_basic,pages_show_list"
        onClick={async()=>{
          const cookies = new Cookies();
          const token = cookies.get('token');
          const profile = cookies.get('profile');
          console.log(profile);
        }}
        callback={async(response)=>{
          const cookies = new Cookies();
          const token = cookies.get('token');
          const profile = cookies.get('profile');


          if(!response['accessToken']){
            alert("Login error.");
            return;
          }

          let socialRes = await setSocialToken({token,profile,tokenSent:response['accessToken'].replace('#',''),social:'igToken'});

          if(!socialRes.data || !socialRes.data.success){
            alert("Error logging you into your social media account.");
            console.log(socialRes.data);
          }else{
            const userDetails = await getUserDetailsService(token,{profileId:profile});
            if(userDetails.data.success){
              console.log(userDetails.data);
              setUser(userDetails.data);
            }else{
              alert("Error getting your details.");
            }
          }

        }}
        render={renderProps => (
            <div className={styles.socialOptionButtonLogin} onClick={renderProps.onClick}>Log In</div>
          )}
         />,
      src2:<InstagramLogin
            clientId="511206957497894"
            buttonText="Log In"
            onSuccess={async(response)=>{
              const cookies = new Cookies();
              const token = cookies.get('token');
              const profile = cookies.get('profile');


              if(!response['accessToken']){
                alert("Login error.");
                return;
              }

              let socialRes = await setSocialToken({token,profile,tokenSent:response['accessToken'].replace('#',''),social:'igToken'});

              if(!socialRes.data || !socialRes.data.success){
                alert("Error logging you into your social media account.");
                console.log(socialRes.data);
              }else{
                const userDetails = await getUserDetailsService(token,{profileId:profile});
                if(userDetails.data.success){
                  console.log(userDetails.data);
                  setUser(userDetails.data);
                }else{
                  alert("Error getting your details.");
                }
              }

            }}
            onFailure={()=>{alert("Instagram login error")}}
            cssClass={styles.socialOptionButtonLogin}
          />,
      url:"https://api.instagram.com/oauth/authorize?client_id=463759005774466&redirect_uri=https://localhost:3001/confirm&scope=user_profile,user_media&response_type=code"
    },*/
    {
      name:"Pinterest",
      ref:"piToken",
      icon:require('../../images/pi.png'),
      src:<div onClick={(event)=>{
        window.open("https://www.pinterest.com/oauth/?client_id=1480959&redirect_uri=https://localhost:3001/confirm&response_type=code&scope=boards:read,pins:read&state='pinterest'");
      }} className={styles.socialOptionButtonLogin}>Log In</div>
    }
  ]



  const ITEMS_PER_PAGE = 25;

  const loadDetails = async()=>{
    const cookies = new Cookies();
    const token = cookies.get('token');
    const profile = cookies.get('profile');

    console.log(continents);

    if(!token){
      navigate('/');
    }else{
      setToken(token);

      const code = searchParams.get('code');

      if(code && code.length > 0){
        //Send code to backend
        let socialRes = await setSocialToken({token,code:code.replace('#',''),social:'igToken'});

        if(!socialRes.data || !socialRes.data.success){
          alert("Error logging you into")
        }
      }

      const userDetails = await getUserDetailsService(token,{profileId:profile});
      if(userDetails.data.success){
        console.log(userDetails.data);
        setUser(userDetails.data);
        setUserHashtags(userDetails.data.dataValues.hashtags || '[]');
        setSelectedCountries(JSON.parse(userDetails.data.dataValues.countries || '[]'));
      }else{
        alert("Error getting your details.");
      }

      const userInputs = await getInputs({token});
      if(userInputs.data.success){
        setInputs(userInputs.data.inputs);
        setScreens(userInputs.data.screens);
      }else{
        alert("Error getting your details.");
      }


      const companyData = await getCompany({token});
      if(companyData.data.success){
        updateColourCodeMain(companyData.data.mainColour);
        updateColourCodeSecond(companyData.data.secondaryColour);
        updateColourCodeButton(companyData.data.buttonsColour);
        if(companyData.data.logoUrl){
          setImageUrl(companyData.data.logoUrl);
        }
      }else{
        alert("Error getting your product details.");
      }

      setLoading(false);
    }

  }

  useEffect(()=>{
    loadDetails();
  },[]);

  const logout = async(social)=>{
    const cookies = new Cookies();
    const token = cookies.get('token');
    const profile = cookies.get('profile');

    const socialLogout = await logoutFromSocial({token,profile,social:social.ref});
    if(socialLogout.data && socialLogout.data.success){
      setUser(socialLogout.data.user);
    }else{
      alert("Please try again.");
    }
  }

  const login = async(social)=>{
    //await setSocialLogin(social);
    //await setSocialLoginOpen(true);
    window.open(social.url);
  }

  const saveSettings = async()=>{
    await setLoading(true);
    const updateData = await updateCompany({mainColour:colourCodeMain.hex?"#"+colourCodeMain.hex:colourCodeMain,secondaryColour:colourCodeSecond.hex?"#"+colourCodeSecond.hex:colourCodeSecond,buttonsColour:colourCodeButton.hex?"#"+colourCodeButton.hex:colourCodeButton,token});

    if(!updateData.data.success){
      alert("Error saving settings.");
      console.log(updateData);
    }

    setLoading(false);
  }

  const saveInput = async()=>{
    await setLoading(true);
    let screenId = null;
    console.log(newInputScreen);
    for(let index = 0;index < screens.length;index++){
      if(newInputScreen == screens[index].screenName) screenId = screens[index].id;
    }

    if(!screenId){
      alert("Please choose a screen to place the input on!");
      setLoading(false);
      return;
    }

    const updateData = await addInput({name:newInputName,type:newInputType,page:parseInt(screenId),mandatory:newInputMandatory?1:0,token});

    if(!updateData.data.success){
      alert("Error: "+updateData.data.error);
      console.log(updateData);
    }else{
      setNewInputWindowOpen(false);
      setInputs(updateData.data.inputs);
    }

    setLoading(false);
  }

  const renderSocialLoginWindow = ()=>{
    if(socialLoginOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setSocialLoginOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={socialIcon}/>
                <div className={styles.discountsRecentBookingsTitleText}>Social Accoutn Login</div>
              </div>
            </div>
            {socialLogin.button}
          </div>
        </div>
      )

    }
  }

  const updateChosenInput = async()=>{
    await setLoading(true);
    let screenId = null;
    for(let index = 0;index < screens.length;index++){
      if(editInputScreen == screens[index].screenName) screenId = screens[index].id;
    }

    if(!screenId){
      alert("Please choose a screen to place the input on!");
      setLoading(false);
      return;
    }

    const updateData = await updateInput({id:editInputId,name:editInputName,type:editInputType,page:parseInt(screenId),mandatory:editInputMandatory?1:0,token});

    if(!updateData.data.success){
      alert("Error: "+updateData.data.error);
    }else{
      setEditInputWindowOpen(false);
      setInputs(updateData.data.inputs);
    }

    setLoading(false);
  }

  const renderEditProductWindow = ()=>{
    if(editInputWindowOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setEditInputWindowOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={productIconPink}/>
                <div className={styles.discountsRecentBookingsTitleText}>Edit Customer Booking Form Input</div>
              </div>
            </div>
            <div className={styles.discountsForm}>
              <TextInput placeholder="Is this input mandatory?*" type="form-checkbox" onChange={(event)=>{setEditInputMandatory(!newInputMandatory)}} initialValue={editInputMandatory}/>
              <br/>
              <TextInput placeholder="Input Name/ Checkbox Text*" type="form-text" onChange={(event)=>{setEditInputName(event.target.value);}} initialValue={editInputName}/>
              <TextInput placeholder="Input Type*" type="dropdown" onChange={(event)=>{setEditInputType(event)}} initialValue={editInputType} options={['Text','Number','Checkbox','Email']}/>
              <TextInput placeholder="What Screen Is This Input For?*" type="dropdown" onChange={(event)=>{setEditInputScreen(event)}} initialValue={editInputScreen} options={screens.map((screen,screenIndex)=>{return screen.screenName})}/>

              <br/><br/>
              <div className={styles.discountsFormNote}>This input will be added to your website on the desired page of your booking form.</div>

              <div className={styles.formButtonContainer}>
                {renderFormEditInputsButton()}
              </div>
            </div>
          </div>
        </div>
      )

    }
  }

  const renderStripeWindow = ()=>{
    if(stripeFormOpen){
      return(
        <div className={styles.discountsNewProductWindowContainer}>
          <div className={styles.discountsNewProductWindow}>
            <img className={styles.discountsXButton} src={xButton} onClick={(event)=>{setStripeFormOpen(false)}} />
            <div className={styles.discountsRecentBookingsTitleContainer}>
              <div className={styles.discountsNewProductHeader}>
                <img className={styles.discountsRecentBookingsTitleIcon} src={productIconPink}/>
                <div className={styles.discountsRecentBookingsTitleText}>Update My Stripe Details</div>
              </div>
            </div>
            <div className={styles.discountsForm}>
              <TextInput placeholder="Stripe Live Public Key*" type="form-text" onChange={(event)=>{setStripePublic(event.target.value);}} initialValue={stripePublic}/>
              <TextInput placeholder="Stripe Live Private Key*" type="form-text" onChange={(event)=>{setStripe(event.target.value);}} initialValue={stripe}/>

              <br/><br/>
              <div className={styles.discountsFormNote}>Your Stripe details will be securely stored.</div>

              <div className={styles.formButtonContainer}>
                {renderFormEditStripeButton()}
              </div>
            </div>
          </div>
        </div>
      )

    }
  }


  const renderFormEditInputsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateChosenInput();}} />
    )
  }

  const renderFormInputsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveInput();}} />
    )
  }

  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveSettings();}} />
    )
  }

  const saveStripe = async()=>{
    await setLoadingForm(true);

    const saveDetails = await updateStripe({token,stripe,stripePublic});
    if(saveDetails.data.success){
      setStripe('');
      setStripePublic('');
      setStripeFormOpen(false);
    }else{
      alert("Error: "+saveDetails.data.error);
    }

    setLoadingForm(false);

  }

  const renderFormEditStripeButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{saveStripe();}} />
    )
  }

  const uploadFile = async(event)=>{
    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const logoResult = await uploadCompanyLogo(formData,token);
    if(logoResult.data.success){
      setImageUrl(logoResult.data.logoUrl);
    }else{
      alert("Error uploading. Please try again.");
    }
    event.target.value = null;
  }

  const renderDashboard = ()=>{

    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={socialIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>My Social Accounts</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>
          {
            SOCIALS.map((social,socialIndex)=>{
              return(
                <div className={styles.socialOption}>
                  <img src={social.icon} className={styles.socialOptionIcon} />
                  <div className={styles.socialOptionName}>{social.name}</div>
                  <div className={styles.socialOptionButtonContainer}>
                    {(user.dataValues[social.ref])?
                      <div onClick={(event)=>{
                          logout(social);
                        }} className={(user.dataValues[social.ref])?styles.socialOptionButtonLogout:styles.socialOptionButtonLogin}>
                          {"Logout"}
                      </div>
                      :
                      (social.src)
                    }
                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    )

  }

  const saveUpdatedHashtags = async(hashtags)=>{

  }

  const renderHashtags = ()=>{
    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={hashtagIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Followed Hashtags</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>
          <div className={styles.hashtagsSubheader}>
            <input id={"hashtag-input"} type="text" className={styles.hashtagInput} placeholder="New Hashtag" type="form-text" onChange={(event)=>{setNewHashtagName(event.target.value)}} initialValue={newHashtagName}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              if(newHashtagName.length === 0) return;
              const hashtagsArray = JSON.parse(userHashtags);
              if(hashtagsArray.length >= 30){
                alert("You are only allowed 30 hashtags at a time.");
                return;
              }
              hashtagsArray.unshift(newHashtagName);
              document.getElementById('hashtag-input').value = '';
              await setNewHashtagName('');
              await setUserHashtags(JSON.stringify(hashtagsArray));
              saveUpdatedHashtags(JSON.stringify(hashtagsArray));
            }} />
          </div>
          {
            (JSON.parse(userHashtags || '[]')).map((hashtag,hashtagIndex)=>{
              return(
                <div className={styles.hashtag}>
                  {hashtag}
                  <div className={styles.hashtagButtonContainer}>
                    <div onClick={async(event)=>{
                      const hashtagsArray = JSON.parse(userHashtags || '[]');
                      hashtagsArray.splice(hashtagIndex, 1);
                      await setUserHashtags(JSON.stringify(hashtagsArray));
                      saveUpdatedHashtags(JSON.stringify(hashtagsArray));
                    }}
                    className={styles.hashtagButton}>-</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={32} color={"#191919"} />
        </div>
      </div>
    )
  }

  const renderLocations = ()=>{

    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={locationIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Locations</div>
            <div className={styles.headerRowRight}>
              <TextInput type="submit-input" border={true} icon={plusIcon} placeholder="Save"
                style={{
                  fontSize:'0.8rem',
                  paddingTop:'2px',
                  paddingBottom:'2px',
                  marginTop:'0px',
                  marginBottom:'0px',
                  margin:'0px'
                }}

                onClick={async(event)=>{
                  const cookies = new Cookies();
                  const token = cookies.get('token');
                  const profile = cookies.get('profile');



                  await setLoading(true);
                  let selectedCountriesArray = [];
                  const allCountries = Object.keys(countries).map((ck)=>{ return {key:ck,...countries[ck]} });

                  for(let index = 0;index < allCountries.length;index++){
                    if(selectedCountries.includes(allCountries[index].key) || selectedContinents.includes(allCountries[index].continent)) selectedCountriesArray.push(allCountries[index].key);
                  }

                  console.log(selectedCountriesArray);
                  setLoading(false);

                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>
          {
            Object.keys(continents).map((continentKey)=>{
              const continent = continents[continentKey];
              if(continent === "Antarctica") return;

              return(
                <div className={styles.continentDisplay}>
                  <div className={styles.continentDisplayText}>
                    <input type="checkbox" checked={selectedContinents.includes(continentKey)} style={styles.checkbox} onChange={async(event)=>{

                      let checked = selectedContinents.includes(continentKey);
                      let continentsTmp = selectedContinents;

                      if(checked){
                        continentsTmp.splice(continentsTmp.indexOf(continentKey),1);
                      }else{
                        continentsTmp.push(continentKey);
                      }

                      await setSelectedContinents(continentsTmp);
                      setUpdate(!update);
                    }} />
                    {continent}
                  </div>
                  {
                    Object.keys(countries).map((countryKey)=>{
                      const country = countries[countryKey];
                      if(country.continent !== continentKey) return;

                      return(
                        <div className={styles.countryDisplay}>
                          <input type="checkbox" checked={selectedCountries.includes(countryKey) || selectedContinents.includes(country.continent)} style={styles.checkbox} onChange={async(event)=>{
                            let checked = selectedCountries.includes(countryKey);
                            let countriesTmp = selectedCountries;

                            if(checked){
                              countriesTmp.splice(countriesTmp.indexOf(countryKey),1);
                            }else{
                              countriesTmp.push(countryKey);
                            }

                            await setSelectedCountries(countriesTmp);
                            setUpdate(!update);
                          }} />
                          {country.name}
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const renderIntegrations = ()=>{
    return(
      <div className={styles.dashboardCardLarge}>
        <div className={styles.dashboardCardHeader}>
          <div className={styles.dashboardCardRow}>
            <img src={storeIcon} className={styles.headerIcon} />
            <div className={styles.headerText}>Integrations</div>
            <div className={styles.headerRowRight}>
            </div>
          </div>
        </div>
        <div className={styles.dashboardCardContent}>

        </div>
      </div>
    )
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={32} color={"#191919"} />
        </div>
      </div>
    )
  }


  if(token){
    return(
      <div className="global-container">
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Store Design"} name={user.NAME+" "+user.SURNAME} userType={user.type} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.NAME+" "+user.SURNAME || "Aalto User"}</div>
              </div>

              <div className="global-header-buttons">
                <img src={settingsIcon} className="global-header-button" onClick={()=>{navigate('/store-design');}}/>
                <img src={bellIcon} className="global-header-button" onClick={()=>{navigate('/dashboard');}}/>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"row", height:"46%", marginTop:"1vh"}}>
              {renderDashboard()}
              {renderHashtags()}
            </div>

            <div style={{display:"flex",flexDirection:"row", height:"46%", marginTop:"1vh"}}>
              {renderLocations()}
              {renderIntegrations()}
            </div>
            {renderSocialLoginWindow()}
            {renderEditProductWindow()}
          </div>
        </div>
      </div>
    )
}


}
