import {useState} from 'react';

// Styles
import submitStyles from './index.module.scss';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { withAlert } from 'react-alert';
import Cookies from 'universal-cookie';

// Assets
import arrowForward from '../../images/arrow-forward.png';


export const SubmitButton = ({title,onClick})=>{

  return(
    <div className={submitStyles.submitButton} onClick={onClick}>
      <div className={submitStyles.submitButtonText}>{title}</div>
      <img src={arrowForward} className={submitStyles.submitButtonIcon} />
    </div>
  )

}
