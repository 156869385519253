import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getProfileDiscover, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';

import logoDark from "../../images/logistible-commerce.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';

export const View = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [error,setError] = useState('');
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [orderCount,setOrderCount] = useState(0);
  const [resultsCount,setResultsCount] = useState(0);
  const [inStoreCount,setInStoreCount] = useState(0);
  const [avgCustomer,setAvgCustomer] = useState(0);
  const [salesCount,setSalesCount] = useState(0);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [newOrderOpen,setNewOrderOpen] = useState(false);
  const [customerName,setCustomerName] = useState('');
  const [customerEmail,setCustomerEmail] = useState('');
  const [customerPhone,setCustomerPhone] = useState('');
  const [customerAddress,setCustomerAddress] = useState('');
  const [customerPostcode,setCustomerPostcode] = useState('');
  const [orderProducts,setOrderProducts] = useState([]);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const navigate = useNavigate();

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');

    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);

  const addProduct = async()=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts.push({productName:'No Selection',colourName:'No Selection',sizeName:'No Selection',productId:'NO_SELECTION',colourId:'NO_SELECTION',sizeId:'NO_SELECTION',length:0,width:0,colours:[],sizes:[]});
    setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const updateOrderProduct = async(index,value)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts[index].productId = parseInt(value.value);
    oldOrderProducts[index].productName = value.label;
    let requiresMeasurements = 0;
    let colours = [];
    for(let productsIndex = 0;productsIndex < products.length;productsIndex++){
      if(products[productsIndex].id === parseInt(value.value)){
        requiresMeasurements = products[productsIndex].requiresMeasurements;
        colours = products[productsIndex].main_products_colours;
      }
    }
    oldOrderProducts[index].requiresMeasurements = requiresMeasurements;
    oldOrderProducts[index].colourId = 'NO_SELECTION';
    oldOrderProducts[index].colourName = 'No Selection';
    oldOrderProducts[index].sizeId = 'NO_SELECTION';
    oldOrderProducts[index].sizeName = 'No Selection';
    //Update Colours
    oldOrderProducts[index].colours = colours;
    oldOrderProducts[index].sizes = [];
    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);

  }

  const updateOrderProductColour = async(productIndex,value)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts[productIndex].colourId = parseInt(value.value);
    oldOrderProducts[productIndex].colourName = value.label;
    oldOrderProducts[productIndex].sizeId = 'NO_SELECTION';
    oldOrderProducts[productIndex].sizeName = 'No Selection';
    for(let coloursIndex = 0;coloursIndex <  oldOrderProducts[productIndex].colours.length;coloursIndex++){
      if(oldOrderProducts[productIndex].colours[coloursIndex].id === parseInt(value.value)){
        oldOrderProducts[productIndex].sizes = oldOrderProducts[productIndex].colours[coloursIndex].main_products_sizes;

      }
    }

    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const updateOrderProductSize = async(productIndex,value)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts[productIndex].sizeId = parseInt(value.value);
    oldOrderProducts[productIndex].sizeName = value.label;
    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const renderFormButton = useCallback(()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{createNewOrder();}} />
    )
  },[loadingForm])


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }

  const createNewOrder = async()=>{
    const newOrder = await createOrder({token,customerName,customerEmail,customerPhone,customerAddress,orderProducts});
    setLoadingForm(true);
    if(newOrder.data.success){
      setLoadingForm(false);
      setNewOrderOpen(false);
    }else{
      alert("Error creating order."+newOrder.data.data);
      setLoadingForm(false);
    }
  }


  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  const downloadCSV = ()=>{
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Name,Address,Phone,Email,Price (€)" + "\r\n";
    results.map((result)=>{return([result.orderDate,result.customerName,result.customerAddress,result.customerPhone,result.customerEmail,result.price])}).forEach((rowArray)=>{
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }


  const setOrderProductLength = async(product,index,value)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts[index].length = parseInt(value);

    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const setOrderProductWidth = async(product,index,value)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts[index].width = parseInt(value);

    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const renderProductDimensionInputs = (product,index)=>{
    if(product.requiresMeasurements === 1){
      return(
        <div>
          <TextInput placeholder="Length (m)*" type="form-number" onChange={(event)=>{setOrderProductLength(product,index,event.target.value)}} initialValue={product.length}/>
          <TextInput placeholder="Width (m)*" type="form-number" onChange={(event)=>{setOrderProductWidth(product,index,event.target.value)}} initialValue={product.width}/>
        </div>
      )
    }
  }

  const removeOrderProduct = async(index)=>{
    let oldOrderProducts = orderProducts;
    oldOrderProducts.splice(index,1);

    await setOrderProducts(oldOrderProducts);
    setOrderProductsCount(orderProductsCount+1);
  }

  const renderNewOrder = ()=>{
    if(!newOrderOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewOrderOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={productIconPink}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Order</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            <TextInput placeholder="Customer Name*" type="form-text" onChange={(event)=>{setCustomerName(event.target.value);}} initialValue={''}/>
            <TextInput placeholder="Customer Email*" type="form-text" onChange={(event)=>{setCustomerEmail(event.target.value);}} initialValue={''}/>
            <TextInput placeholder="Customer Phone*" type="form-text" onChange={(event)=>{setCustomerPhone(event.target.value);}} initialValue={''}/>
            <TextInput placeholder="Customer Address*" type="form-text" onChange={(event)=>{setCustomerAddress(event.target.value);}} initialValue={''}/>
            <TextInput placeholder="Customer Postcode*" type="form-text" onChange={(event)=>{setCustomerPostcode(event.target.value);}} initialValue={''}/>

            <div className={styles.ordersFormColours}>

              <div className={styles.ordersFormColoursTitle}>
                Order Products*
                <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={(event)=>{addProduct()}} />
              </div>
                {orderProducts.map((product,productIndex)=>{
                  return(
                    <div style={{marginTop:"5px",paddingBottom:"22px",paddingTop:"16px",borderBottom:"1px solid #ABABAB",fontSize:"0.9rem"}}>
                      <div style={{color:"#ABABAB",marginBottom:"-10px"}}>Product {productIndex+1}:</div>
                      <TextInput placeholder="Product*" type="products-dropdown" onChange={(value)=>{updateOrderProduct(productIndex,value)}} options={products} initialValue={{value:product.productId,label:product.productName}}/>
                      <TextInput placeholder="Colour*" type="products-dropdown" onChange={(value)=>{updateOrderProductColour(productIndex,value)}} options={product.colours} initialValue={{value:product.colourId,label:product.colourName}}/>
                      <TextInput placeholder="Size*" type="products-dropdown" onChange={(value)=>{updateOrderProductSize(productIndex,value)}} options={product.sizes} initialValue={{value:product.sizeId,label:product.sizeName}}/>
                      <img src={removeIcon} className={styles.removeIcon} onClick={(event)=>{removeOrderProduct(productIndex)}}/>

                      {renderProductDimensionInputs(product,productIndex)}
                    </div>
                  )
                })}
            </div>
            <div className={styles.formButtonContainer}>
              {renderFormButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewOrder()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Home"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.NAME+" "+user.SURNAME || "Aalto User"}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px"}} className={"global-dashboard-row-card-white"}>

                  <div className={styles.cardHeader}>New Case</div>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                    <TextInput placeholder="PIAB reference number*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="Claimant Name*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="Claimant Phone*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="Claimant Address*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    </div>
                    <div className={styles.cardColumn}>
                    <TextInput placeholder="Claimant Email*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="Claimant DOB*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="Incident Date*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    <TextInput placeholder="LDTA*" type="form-text" onChange={(value)=>{}} initialValue={''}/>

                    </div>
                  </div>
                  <br/><br/>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Solicitor Name*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                      <TextInput placeholder="Solicitor Email*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                      <TextInput placeholder="Special Not to Solicitor*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                      <br/><br/>
                      <TextInput placeholder="Medic Type Needed*" type="form-text" onChange={(value)=>{}} initialValue={''}/>

                    </div>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Solicitor Reference*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                      <TextInput placeholder="Backup Solicitor Email*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    </div>
                  </div>

                  <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{}} />
                  </div>

                </div>
              </div>

              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px"}} className={"global-dashboard-row-card-white"}>

                  <div className={styles.cardHeader}>New Medical Apt.</div>
                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Medical Expert*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                      <TextInput placeholder="Expert Type*" type="form-text" onChange={(value)=>{}} initialValue={''}/>

                    </div>

                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Clinic Location*" type="form-text" onChange={(value)=>{}} initialValue={''}/>

                    </div>
                  </div>

                  <div className={styles.cardColumnContainer}>
                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Clinic Address*" type="form-text" onChange={(value)=>{}} initialValue={''}/>

                    </div>

                    <div className={styles.cardColumn}>
                      <TextInput placeholder="Expert Email*" type="form-text" onChange={(value)=>{}} initialValue={''}/>
                    </div>
                  </div>

                  <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
                    <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{}} />
                  </div>
                </div>
              </div>



            </div>




          </div>
        </div>
      </div>
    )
}


}
