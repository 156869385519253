import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {setMedicProfileImageService, updateMedicTypes, getMedicTypes, freeDate, sendConfirmationEmails, sendReminderEmails, updateTimes, createDateTime, deleteDateTime, updateMedic, updateSolicitor, getAllSolicitors, getAllMedics, createMedicContact, createSolicitorContact, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';

import logoDark from "../../images/logistible-commerce.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';


const COUNTIES = [
    'Antrim',
    'Armagh',
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Derry',
    'Donegal',
    'Down',
    'Dublin',
    'Fermanagh',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Tyrone',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow'
]

export const Contact = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [error,setError] = useState('');

  const [newDoctorAnonymous, setNewDoctorAnonymous] = useState(0);
  const [newDoctorQualifications, setNewDoctorQualifications] = useState('');
  const [newDoctorBio, setNewDoctorBio] = useState('');
  const [newDoctorCategories, setNewDoctorCategories] = useState([]);
  const [newDoctorSpecialties, setNewDoctorSpecialties] = useState([]);
  const [newDoctorCounties, setNewDoctorCounties] = useState([]);
  const [newDoctorCategoriesText, setNewDoctorCategoriesText] = useState('');
  const [newDoctorSpecialtiesText, setNewDoctorSpecialtiesText] = useState('');
  const [newDoctorCountiesText, setNewDoctorCountiesText] = useState(COUNTIES[0]);
  const [newDoctorName, setNewDoctorName] = useState('');
  const [newDoctorSpecialty, setNewDoctorSpecialty] = useState('');
  const [newDoctorContactEmail, setNewDoctorContactEmail] = useState('');
  const [newDoctorDisplayEmail, setNewDoctorDisplayEmail] = useState('');
  const [newDoctorPhone, setNewDoctorPhone] = useState('');
  const [newDoctorClinics, setNewDoctorClinics] = useState([]);
  const [newClinicText, setNewClinicText] = useState('');
  const [newDoctorInternalNote, setNewDoctorInternalNote] = useState('');

  const newDateRef = useRef(null);
  const newTimeRef = useRef(null);

  const emailRef = useRef(null);
  const emailDisplayRef = useRef(null);
  const locationRef = useRef(null);

  const [newDate, setNewDate] = useState(new Date());
  const [newTime, setNewTime] = useState('');
  const [CATEGORIES, setCategories] = useState([])
  const [SPECIALTIES, setSpecialties] = useState([])

  const [editDoctorAnonymous, setEditDoctorAnonymous] = useState(0);
  const [editDoctorQualifications, setEditDoctorQualifications] = useState('');
  const [editDoctorBio, setEditDoctorBio] = useState('');
  const [editDoctorCategories, setEditDoctorCategories] = useState([]);
  const [editDoctorSpecialties, setEditDoctorSpecialties] = useState([]);
  const [editDoctorCounties, setEditDoctorCounties] = useState([]);
  const [editDoctorCategoriesText, setEditDoctorCategoriesText] = useState(null);
  const [editDoctorSpecialtiesText, setEditDoctorSpecialtiesText] = useState(null);
  const [editDoctorCountiesText, setEditDoctorCountiesText] = useState(COUNTIES[0]);
  const [editDoctorName, setEditDoctorName] = useState('');
  const [editDoctorSpecialty, setEditDoctorSpecialty] = useState('');
  const [editDoctorContactEmail, setEditDoctorContactEmail] = useState('');
  const [editDoctorDisplayEmail, setEditDoctorDisplayEmail] = useState('');
  const [editDoctorPhone, setEditDoctorPhone] = useState('');
  const [editDoctorClinics, setEditDoctorClinics] = useState([]);
  const [editDoctorCounty, setEditDoctorCounty] = useState(COUNTIES[0]);
  const [editClinicText, setEditClinicText] = useState('');
  const [editDoctorInternalNote, setEditDoctorInternalNote] = useState('');
  const [editMedicOpen, setEditMedicOpen] = useState(false);
  const [medicTimesOpen, setMedicTimesOpen] = useState(false);
  const [chosenTimes, setChosenTimes] = useState([]);
  const [editDoctorId, setEditDoctorId] = useState(null);
  const [editDoctorIndex, setEditDoctorIndex] = useState(null);
  const [editMedicProfileImage, setEditMedicProfileImage] = useState('');
  const [dateTimeLocation, setDateTimeLocation] = useState('');

  const [newSolicitorFirmName, setNewSolicitorFirmName] = useState('');
  const [newSolicitorPrimaryEmail, setNewSolicitorPrimaryEmail] = useState('');
  const [newSolicitorSecondaryEmail, setNewSolicitorSecondaryEmail] = useState('');
  const [newSolicitorAddress, setNewSolicitorAddress] = useState('');
  const [newSolicitorPhone, setNewSolicitorPhone] = useState('');
  const [newSolicitorInternalNote, setNewSolicitorInternalNote] = useState('');
  const [newSolicitorReference, setNewSolicitorReference] = useState('');

  const [dateExisting, setDateExisting] = useState(new Date());
  const [dateNew, setDateNew] = useState(new Date());
  const [dateTimeLocationNew, setDateTimeLocationNew ] = useState('');
  const [dateTimeLocationExisting, setDateTimeLocationExisting ] = useState('');

  const [editSolicitorFirmName, setEditSolicitorFirmName] = useState('');
  const [editSolicitorPrimaryEmail, setEditSolicitorPrimaryEmail] = useState('');
  const [editSolicitorSecondaryEmail, setEditSolicitorSecondaryEmail] = useState('');
  const [editSolicitorAddress, setEditSolicitorAddress] = useState('');
  const [editSolicitorPhone, setEditSolicitorPhone] = useState('');
  const [editSolicitorInternalNote, setEditSolicitorInternalNote] = useState('');
  const [editSolicitorOpen, setEditSolicitorOpen] = useState(false);
  const [editSolicitorId, setEditSolicitorId] = useState(null);
  const [editSolicitorIndex, setEditSolicitorIndex] = useState(null);
  const [editSolicitorReference, setEditSolicitorReference] = useState('');
  const [doctorDates, setDoctorDates] = useState([]);
  const [searchDate, setSearchDate] = useState(null);
  const [doctorDatesOpen, setDoctorDatesOpen] = useState(false);
  const [recordOpen, setRecordOpen] = useState(false);
  const [medicTypesOpen, setMedicTypesOpen] = useState(false);
  const [medicProfileImage, setMedicProfileImage] = useState(null)

  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [solicitors, setSolicitors] = useState([]);
  const [medics, setMedics] = useState([]);
  const [newMedicOpen, setNewMedicOpen] = useState(false)
  const [newSolicitorOpen, setNewSolicitorOpen] = useState(false)
  const [clinicUpdate, setClinicUpdate] = useState(false)
  const [medicSearch, setMedicSearch] = useState('')
  const [solicitorSearch, setSolicitorSearch] = useState('')
  const [currentRecord, setCurrentRecord] = useState({})
  const navigate = useNavigate();

  const [newCategoryText, setNewCategoryText] = useState('')
  const [newSpecialtyText, setNewSpecialtyText] = useState('')
  const [newSpecialtyCategory, setNewSpecialtyCategory] = useState('')

  const formatDate = (date)=>{
    return (new Date(date).getDate())+"/"+(new Date(date).getMonth()+1)+"/"+(new Date(date).getFullYear())
  }

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');

    if(!token){
      navigate('/');
    }else{
      setToken(token);
      console.log(token);
      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const medicDetails = await getAllMedics({token});
      if(medicDetails.data.success){
        setMedics(medicDetails.data.medics);
      }else{
        console.log(medicDetails);
        alert("Error getting your details.");
      }

      const medicTypes = await getMedicTypes({token})

      if(medicTypes.data.success){
        setCategories(medicTypes.data.categories)
        console.log(medicTypes.data.categories)
        setSpecialties(medicTypes.data.specialties)
        setNewDoctorSpecialtiesText(medicTypes.data.specialties[0] || null)
        setNewDoctorCategoriesText(medicTypes.data.categories[0] || null)
        setEditDoctorSpecialtiesText(medicTypes.data.specialties[0] || null)
        setEditDoctorCategoriesText(medicTypes.data.categories[0] || null)
      }else{
        console.log(medicTypes.data)
      }


      const solicitorDetails = await getAllSolicitors({token});
      if(solicitorDetails.data.success){
        setSolicitors(solicitorDetails.data.solicitors);
      }else{
        console.log(solicitorDetails);
        alert("Error getting your details.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);

  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <GridLoader  size={10} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{}} />
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }

  const createMedic = async()=>{
    setLoading(true);
    let formData = new FormData();

    formData.append('name', newDoctorName)
    formData.append('email', newDoctorContactEmail)
    formData.append('displayEmail', newDoctorDisplayEmail)
    formData.append('clinics', newDoctorClinics.join('[****SEP****]'))
    formData.append('phone', newDoctorPhone)
    formData.append('specialty', newDoctorSpecialty)
    formData.append('internalNote', newDoctorInternalNote)
    formData.append('specialties', newDoctorSpecialties.join('[****SEP****]'))
    formData.append('categories', newDoctorCategories.join('[****SEP****]'))
    formData.append('county', newDoctorCounties.join('[****SEP****]'))
    formData.append('anonymous', newDoctorAnonymous?1:0)
    formData.append('bio', newDoctorBio)
    formData.append('qualifications', newDoctorQualifications)

    const uploadResult = await createMedicContact(formData,token);
    if(uploadResult.data.success){
      setNewDoctorName('')
      setNewDoctorContactEmail('')
      setNewDoctorDisplayEmail('')
      setNewDoctorSpecialty('')
      setNewDoctorPhone('')
      setNewDoctorInternalNote('')
      setNewMedicOpen(false)
      setNewDoctorClinics([])
      setNewDoctorCategories([])
      setNewDoctorSpecialties([])
      setNewDoctorCounties([])
      setNewDoctorBio('')
      setNewDoctorQualifications('')

      let medicsTmp = medics;
      medicsTmp.push(uploadResult.data.medic);
      await setMedics(medicsTmp);
      await setClinicUpdate(!clinicUpdate);
      alert('Medic Saved');
    }else{
      alert("Error uploading. Please try again.");
    }
    setLoading(false);
  }

  const updateChosenMedic = async()=>{
    setLoading(true);
    let formData = new FormData();

    formData.append('medicId', editDoctorId)
    formData.append('name', editDoctorName)
    formData.append('email', editDoctorContactEmail)
    formData.append('displayEmail', editDoctorDisplayEmail)
    formData.append('clinics', editDoctorClinics.join('[****SEP****]'))
    formData.append('phone', editDoctorPhone)
    formData.append('specialty', editDoctorSpecialty)
    formData.append('internalNote', editDoctorInternalNote)
    formData.append('specialties', editDoctorSpecialties.join('[****SEP****]'))
    formData.append('categories', editDoctorCategories.join('[****SEP****]'))
    formData.append('county', editDoctorCounties.join('[****SEP****]'))
    formData.append('anonymous', editDoctorAnonymous?1:0)
    formData.append('bio', editDoctorBio)
    formData.append('qualifications', editDoctorQualifications)

    console.log(editDoctorAnonymous)
    console.log(editDoctorAnonymous?1:0)

    const uploadResult = await updateMedic(formData,token);
    if(uploadResult.data.success){
      setEditDoctorName('')
      setEditDoctorContactEmail('')
      setEditDoctorDisplayEmail('')
      setEditDoctorSpecialty('')
      setEditDoctorPhone('')
      setEditDoctorInternalNote('')
      setEditMedicOpen(false)
      setEditDoctorClinics([])
      setEditDoctorSpecialties([])
      setEditDoctorCategories([])

      console.log(uploadResult.data.medic)

      const medicDetails = await getAllMedics({token});
      if(medicDetails.data.success){
        setMedics(medicDetails.data.medics);
      }

      await setClinicUpdate(!clinicUpdate);
      alert('Medic Saved');
    }else{
      alert("Error uploading. Please try again.");
    }
    setLoading(false);
  }

  const createSolicitor = async()=>{
    setLoading(true);
    let formData = new FormData();

    formData.append('firmName', newSolicitorFirmName)
    formData.append('primaryEmail', newSolicitorPrimaryEmail)
    formData.append('secondaryEmail', newSolicitorSecondaryEmail)
    formData.append('address', newSolicitorAddress)
    formData.append('phone', newSolicitorPhone)
    formData.append('internalNote', newSolicitorInternalNote)
    formData.append('reference', newSolicitorReference)

    const uploadResult = await createSolicitorContact(formData,token);
    if(uploadResult.data.success){
      setNewSolicitorFirmName('')
      setNewSolicitorPrimaryEmail('')
      setNewSolicitorSecondaryEmail('')
      setNewSolicitorPhone('')
      setNewSolicitorInternalNote('')
      setNewSolicitorOpen(false)

      let solicitorsTmp = solicitors;
      solicitorsTmp.push(uploadResult.data.solicitor);
      await setSolicitors(solicitorsTmp);
      await setClinicUpdate(!clinicUpdate);
      alert('Solicitor Saved');
    }else{
      alert("Error uploading. Please try again.");
    }
    setLoading(false);
  }

  const updateChosenSolicitor = async()=>{
    setLoading(true);
    let formData = new FormData();

    formData.append('solicitorId', editSolicitorId)
    formData.append('firmName', editSolicitorFirmName)
    formData.append('primaryEmail', editSolicitorPrimaryEmail)
    formData.append('secondaryEmail', editSolicitorSecondaryEmail)
    formData.append('address', editSolicitorAddress)
    formData.append('phone', editSolicitorPhone)
    formData.append('internalNote', editSolicitorInternalNote)
    formData.append('reference', editSolicitorReference)

    const uploadResult = await updateSolicitor(formData,token);
    if(uploadResult.data.success){
      setEditSolicitorFirmName('')
      setEditSolicitorPrimaryEmail('')
      setEditSolicitorSecondaryEmail('')
      setEditSolicitorPhone('')
      setEditSolicitorInternalNote('')
      setEditSolicitorOpen(false)

      let solicitorsTmp = solicitors;
      solicitorsTmp[editSolicitorIndex] = uploadResult.data.solicitor;
      await setSolicitors(solicitorsTmp);
      await setClinicUpdate(!clinicUpdate);
      alert('Solicitor Saved');
    }else{
      alert("Error uploading. Please try again.");
    }
    setLoading(false);
  }

  const updatedAttending = async(timeslotIndex)=>{
    let recordsTmp = doctorDates;
    doctorDates[timeslotIndex].attended = !doctorDates[timeslotIndex].attended;
    doctorDates[timeslotIndex].edited = true;
    await setDoctorDates(recordsTmp);
    setClinicUpdate(!clinicUpdate);
  }

  const updatedConfirmed = async(timeslotIndex)=>{
    let recordsTmp = doctorDates;
    doctorDates[timeslotIndex].confirmed = !doctorDates[timeslotIndex].confirmed;
    doctorDates[timeslotIndex].edited = true;
    await setDoctorDates(recordsTmp);
    setClinicUpdate(!clinicUpdate);
  }


  const renderNewMedic = ()=>{
    if(!newMedicOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewMedicOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Medic Contact</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            <TextInput placeholder="Medic Name*" type="form-text" onChange={(value)=>{setNewDoctorName(value)}} initialValue={newDoctorName}/>
            <TextInput placeholder="Medic Email*" type="form-text" onChange={(value)=>{setNewDoctorContactEmail(value)}} initialValue={newDoctorContactEmail}/>
            <TextInput placeholder="Display Email*" type="form-text" onChange={(value)=>{setNewDoctorDisplayEmail(value)}} initialValue={newDoctorDisplayEmail}/>

            <TextInput placeholder="Medic Specialty*" type="form-text" onChange={(value)=>{setNewDoctorSpecialty(value)}} initialValue={newDoctorSpecialty}/>
            <TextInput placeholder="Medic Phone*" type="form-text" onChange={(value)=>{setNewDoctorPhone(value)}} initialValue={newDoctorPhone}/>
            <TextInput placeholder="Medic Bio*" type="form-text" onChange={(value)=>{setNewDoctorBio(value)}} initialValue={newDoctorBio}/>
            <TextInput placeholder="Medic Qualifications*" type="form-text" onChange={(value)=>{setNewDoctorQualifications(value)}} initialValue={newDoctorQualifications}/>

            <br/><br/>
            <input type="checkbox" id="checkbox1" name="checkbox1" checked={newDoctorAnonymous} onChange={(event)=>{setNewDoctorAnonymous(!newDoctorAnonymous)}}></input>
            <span style={{paddingRight:"0px"}}> Is this Medic Anonymous?</span>


            <br/><br/><br/>
            Counties<br/>
            <TextInput placeholder="County*" type="dropdown" options={COUNTIES} onChange={(value)=>{setNewDoctorCountiesText(value)}} initialValue={newDoctorCountiesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = newDoctorCounties;
              clinicsTmp.push(newDoctorCountiesText);
              await setNewDoctorCounties(clinicsTmp);
              await setNewClinicText('');
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              newDoctorCounties.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = newDoctorCounties;
                      clinicsTmp.splice(clinicIndex,1);
                      await setNewDoctorCounties(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/>

            Categories<br/>
            <TextInput placeholder="Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })} onChange={(value)=>{setNewDoctorCategoriesText(value)}} initialValue={newDoctorCategoriesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = newDoctorCategories;
              clinicsTmp.push(newDoctorCategoriesText);
              await setNewDoctorCategories(clinicsTmp);
              await setNewClinicText('');
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              newDoctorCategories.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = newDoctorCategories;
                      clinicsTmp.splice(clinicIndex,1);
                      await setNewDoctorCategories(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/>

            Specialties<br/>
            <TextInput placeholder="Specialty*" type="dropdown" options={SPECIALTIES.map((s,si)=>{ return s.name })} onChange={(value)=>{setNewDoctorSpecialtiesText(value)}} initialValue={newDoctorSpecialtiesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = newDoctorSpecialties;
              clinicsTmp.push(newDoctorSpecialtiesText);
              await setNewDoctorSpecialties(clinicsTmp);
              await setNewClinicText('');
              await setNewMedicOpen(false);
              await setNewMedicOpen(true);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              newDoctorSpecialties.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = newDoctorSpecialties;
                      clinicsTmp.splice(clinicIndex,1);
                      await setNewDoctorSpecialties(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/>
            Clinics<br/>
            <TextInput placeholder="Add Clinic*" type="form-text" onChange={(value)=>{setNewClinicText(value)}} initialValue={newClinicText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = newDoctorClinics;
              clinicsTmp.push(newClinicText);
              await setNewDoctorClinics(clinicsTmp);
              await setNewClinicText('');
              await setNewMedicOpen(false);
              await setNewMedicOpen(true);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              newDoctorClinics.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = newDoctorClinics;
                      clinicsTmp.splice(clinicIndex,1);
                      await setNewDoctorClinics(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/>
            <TextInput placeholder="Internal Note" type="form-textarea" onChange={(value)=>{setNewDoctorInternalNote(value)}} initialValue={newDoctorInternalNote}/>
            <br/><br/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{createMedic()}} />
          </div>
        </div>
      </div>
    )
  }

  const renderRecord = ()=>{
    if(recordOpen){
      return(
        <div className={styles.ordersNewProductWindowContainer}>
          <div className={styles.viewTimesWindow}>
            <img className={styles.ordersXButton} src={xButton} onClick={async(event)=>{
              await setRecordOpen(false);
            }} />
            <div className={styles.ordersRecentBookingsTitleContainer}>
              <div className={styles.ordersNewProductHeader}>
                <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
                <div className={styles.ordersRecentBookingsTitleText}>View Record</div>
              </div>
            </div>
            <div className={styles.ordersForm}>
              Claimant Name: {currentRecord.ml_record?.claimantName}<br/>
              Claimant Email: {currentRecord.ml_record?.claimantEmail}<br/>
              Claimant DOB: {currentRecord.ml_record?.claimantDOB}<br/>
              Claimant Address: {currentRecord.ml_record?.claimantAddress}<br/><br/>

              Appointment Date: {currentRecord.ml_record?.appointmentDate}<br/>
              appointmentTime: {currentRecord.ml_record?.appointmentTime}<br/><br/>

              Solicitor: {currentRecord.ml_record?.solicitorName}<br/>
              Solicitor Named Email: {currentRecord.ml_record?.solicitorEmail}<br/>
              Solicitor Admin Email: {currentRecord.ml_record?.backupSolicitorEmail}<br/>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderDoctorDates = ()=>{
    if(doctorDatesOpen){
      return(
        <div className={styles.ordersNewProductWindowContainer}>
          <div className={styles.viewTimesWindow}>
            <img className={styles.ordersXButton} src={xButton} onClick={async(event)=>{
              await setDoctorDatesOpen(false);
              await setEditMedicOpen(true);}} />
            <div className={styles.ordersRecentBookingsTitleContainer}>
              <div className={styles.ordersNewProductHeader}>
                <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
                <div className={styles.ordersRecentBookingsTitleText}>View Medic Timeslots</div>
              </div>
            </div>
            <div className={styles.ordersForm}>
              <TextInput placeholder="Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setSearchDate(value);}} initialValue={searchDate}/>
              <img className={styles.searchXButton} src={xButton} onClick={async(event)=>{
                setSearchDate(null)
              }} />
              <br/><br/>
              <table style={{width:"100%"}}>
                <tr className={styles.ordersRecentBookingsTableHeader}>
                  <th className={styles.productsResultHeaderItem}>Date</th>
                  <th className={styles.productsResultHeaderItem}>Time</th>
                  <th className={styles.productsResultHeaderItem}>Location</th>
                  <th className={styles.productsResultHeaderItem}>Claimant</th>
                  <th className={styles.productsResultHeaderItem}>Status</th>
                  <th className={styles.productsResultHeaderItem}>DNA</th>
                  <th className={styles.productsResultHeaderItem}>Confirm Email</th>
                  <th className={styles.productsResultHeaderItem}>Free Date</th>
                </tr>
                {
                  doctorDates.filter((date)=>{ return (!searchDate || date.date == formatDate(searchDate))}).map((record, recordIndex)=>{

                    let realIndex;

                    doctorDates.map((date, dateIndex)=>{
                      if(date.id == record.id)  realIndex = dateIndex;
                    })

                    if(recordIndex % 2 != 0){
                      return(
                        <tr className={styles.productsRowEven}>
                          <td className={styles.ordersRecentBookingsRowEvenItem}>{record.date}</td>
                          <td className={styles.ordersRecentBookingsRowEvenItem}>{record.time}</td>
                          <td className={styles.ordersRecentBookingsRowEvenItem}>{record.location}</td>
                          <td className={styles.ordersRecentBookingsRowEvenItem} onClick={async(event)=>{
                            await setCurrentRecord(record);
                            setRecordOpen(true)
                          }}>{record.ml_record?.claimantName}</td>
                          <td className={styles.ordersRecentBookingsRowEvenItem}>
                            <input type="checkbox" id="checkbox1" name="checkbox1" checked={record.attended?true:false} onChange={(event)=>{
                              updatedAttending(recordIndex)
                            }}></input>
                            <span style={{paddingRight:"0px"}}> Not Attended</span><br/>

                            <input type="checkbox" id="checkbox1" name="checkbox1" checked={record.confirmed?true:false} onChange={(event)=>{
                              updatedConfirmed(recordIndex)
                            }}></input>
                            <span style={{paddingRight:"0px"}}> Confirmed</span>
                          </td>
                          <td>
                            <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="DNA" onClick={async(event)=>{
                              const cookies = new Cookies();
                              const token = await cookies.get('token');

                              if(!record.attended) {
                                alert('Please ensure the appointment was not attended before sending.');
                                return;
                              }

                              const sendReminderRes = await sendReminderEmails({token, appointments:[record]})

                              if(sendReminderRes.data.success){
                                alert("Reminder emails sent.")
                              }else{
                                alert("Error sending emails.")
                              }
                            }} />
                          </td>
                          <td>
                            <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Confirm Email" onClick={async(event)=>{
                              const cookies = new Cookies();
                              const token = await cookies.get('token');

                              if(record.confirmed) {
                                alert('Please ensure the appointment was not confirmed before sending.');
                                return;
                              }

                              const sendReminderRes = await sendConfirmationEmails({token, appointments:[record]})

                              if(sendReminderRes.data.success){
                                alert("Confirmation emails sent.")
                              }else{
                                alert("Error sending emails.")
                              }
                            }} />
                          </td>
                          <td>
                            <TextInput type="submit-input" icon={plusIcon} placeholder="Free Date" onClick={async(event)=>{
                              const cookies = new Cookies();
                              const token = await cookies.get('token');
                              const dateRes = await freeDate({token,dateId:record.id,recordId:record.recordId});

                              if(dateRes.data.success){
                                let tmp = doctorDates;
                                tmp[realIndex].available = 1;
                                tmp[realIndex].record = null;
                                tmp[realIndex].ml_record = null;
                                await setDoctorDates(tmp);
                                await setClinicUpdate(!clinicUpdate);
                                getAllMedics({token});
                                alert("Saved updates");
                              }else{
                                alert("Please try again");
                              }
                            }} />
                          </td>
                        </tr>
                      )
                    }

                    return(
                      <tr className={styles.productsRowOdd}>
                        <td className={styles.ordersRecentBookingsRowEvenItem}>{record.date}</td>
                        <td className={styles.ordersRecentBookingsRowEvenItem}>{record.time}</td>
                        <td className={styles.ordersRecentBookingsRowEvenItem}>{record.location}</td>
                        <td className={styles.ordersRecentBookingsRowEvenItem}  onClick={async(event)=>{
                          await setCurrentRecord(record);
                          setRecordOpen(true)
                        }}>{record.ml_record?.claimantName}</td>
                        <td className={styles.ordersRecentBookingsRowEvenItem}>
                          <input type="checkbox" id="checkbox1" name="checkbox1" checked={record.attended?true:false} onChange={(event)=>{
                            updatedAttending(recordIndex)
                          }}></input>
                          <span style={{paddingRight:"0px"}}> Not Attended</span><br/>

                          <input type="checkbox" id="checkbox1" name="checkbox1" checked={record.confirmed?true:false} onChange={(event)=>{
                            updatedConfirmed(recordIndex)
                          }}></input>
                          <span style={{paddingRight:"0px"}}> Confirmed</span>
                        </td>
                        <td>
                          <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="DNA" onClick={async(event)=>{
                            const cookies = new Cookies();
                            const token = await cookies.get('token');

                            if(!record.attended) {
                              alert('Please ensure the appointment was not confirmed before sending.');
                              return;
                            }

                            const sendReminderRes = await sendReminderEmails({token, appointments:[record]})

                            if(sendReminderRes.data.success){
                              alert("Reminder emails sent.")
                            }else{
                              alert("Error sending emails.")
                            }
                          }} />
                        </td>
                        <td>
                          <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Confirm Email" onClick={async(event)=>{
                            const cookies = new Cookies();
                            const token = await cookies.get('token');

                            if(record.confirmed) {
                              alert('Please ensure the appointment was not attended before sending.');
                              return;
                            }

                            const sendReminderRes = await sendConfirmationEmails({token, appointments:[record]})

                            if(sendReminderRes.data.success){
                              alert("Confirmation emails sent.")
                            }else{
                              alert("Error sending emails.")
                            }
                          }} />
                        </td>
                        <td>
                          <TextInput type="submit-input" icon={plusIcon} placeholder="Free Date" onClick={async(event)=>{
                            const cookies = new Cookies();
                            const token = await cookies.get('token');
                            const dateRes = await freeDate({token,dateId:record.id, recordId:record.recordId});

                            if(dateRes.data.success){
                              let tmp = doctorDates;
                              tmp[realIndex].available = 1;
                              tmp[realIndex].record = null;
                              tmp[realIndex].ml_record = null;
                              await setDoctorDates(tmp);
                              await setClinicUpdate(!clinicUpdate);
                              getAllMedics({token});
                              alert("Saved updates");
                            }else{
                              alert("Please try again");
                            }
                          }} />
                        </td>
                      </tr>
                    )
                  })
                }
              </table>
              <br/><br/>
              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save" onClick={async(event)=>{
                const cookies = new Cookies();
                const token = await cookies.get('token');

                const updateTimesRes = await updateTimes({token,times:doctorDates.filter((date, dateIndex)=>{ return date.edited })})

                if(updateTimesRes.data.success){
                  const medicDetails = await getAllMedics({token});
                  if(medicDetails.data.success){
                    setMedics(medicDetails.data.medics);
                  }
                  alert("Timeslots saved.")
                }else{
                  alert("Error saving your details.")
                }
              }} />

              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="DNA" onClick={async(event)=>{
                const cookies = new Cookies();
                const token = await cookies.get('token');

                const sendReminderRes = await sendReminderEmails({token, appointments: doctorDates.filter((date)=>{ return ((!searchDate || date.date == formatDate(searchDate)) && date.attended)})})

                if(sendReminderRes.data.success){
                  alert("Reminder emails sent.")
                }else{
                  alert("Error sending emails.")
                }
              }} />

              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Confirm Emails" onClick={async(event)=>{
                const cookies = new Cookies();
                const token = await cookies.get('token');

                const sendReminderRes = await sendConfirmationEmails({token, appointments: doctorDates.filter((date)=>{ return ((!searchDate || date.date == formatDate(searchDate)) && !date.confirmed)})})

                if(sendReminderRes.data.success){
                  alert("Confirmation emails sent.")
                }else{
                  alert("Error sending emails.")
                }
              }} />

            </div>
          </div>
        </div>
      )
    }
  }

  const renderMedicTimes = ()=>{
    if(medicTimesOpen){
      return(
        <div className={styles.ordersNewProductWindowContainer}>
          <div className={styles.locationsWindow}>
            <img className={styles.ordersXButton} src={xButton} onClick={async(event)=>{
              await setMedicTimesOpen(false);
              await setEditMedicOpen(true);}} />
            <div className={styles.ordersRecentBookingsTitleContainer}>
              <div className={styles.ordersNewProductHeader}>
                <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
                <div className={styles.ordersRecentBookingsTitleText}>Edit Medic Times</div>
              </div>
            </div>
            <div className={styles.locationsForm}>
              <div className={styles.locationsListColumn}>
                <TextInput placeholder="Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                  setDateTimeLocation(value)
                }} initialValue={dateTimeLocation}/>
                <TextInput placeholder="Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setNewDate(value)}} initialValue={newDate}/>
                <TextInput placeholder="Time*" id="efg" type="form-text" ref={newTimeRef} onChange={(value)=>{setNewTime(value)}} initialValue={newTime}/>
                <br/><br/>
                <TextInput type="submit-input"  style={{marginLeft:'0px'}} icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                  let timesTmp = chosenTimes;
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  const res = await createDateTime({token,date:formatDate(newDate),time:newTime,doctorId:editDoctorId, location:dateTimeLocation});
                  timesTmp.push(res.data.newDateTime);
                  await setChosenTimes(timesTmp);
                  await setClinicUpdate(!clinicUpdate);
                }} />
                <br/><br/>
                <div className={styles.line}></div>
                Duplicate A Location and Date Schedule<br/>
                <TextInput placeholder="Chosen Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                  setDateTimeLocationExisting(value)
                }} initialValue={dateTimeLocationExisting}/>
                <TextInput placeholder="New Location" options={editDoctorClinics} type="dropdown" solicitors={true} onChange={async(value)=>{
                  setDateTimeLocationNew(value)
                }} initialValue={dateTimeLocationNew}/>
                <br/><br/>
                <TextInput placeholder="Existing Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setDateExisting(value)}} initialValue={dateExisting}/>
                <TextInput placeholder="New Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setDateNew(value)}} initialValue={dateNew}/>
                <br/><br/>
                <TextInput style={{marginLeft:'0px'}} type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
                  let cloneTimes = chosenTimes.filter((time)=>{ return (time.date == formatDate(dateExisting) && time.location == dateTimeLocationExisting) });
                  let timesTmp = chosenTimes;
                  const cookies = new Cookies();
                  const token = await cookies.get('token');

                  for(let i = 0;i < cloneTimes.length;i++){
                    const res = await createDateTime({token,date:formatDate(dateNew),time:cloneTimes[i].time,doctorId:editDoctorId, location:dateTimeLocationNew});
                    timesTmp.push(res.data.newDateTime);
                  }

                  await setChosenTimes(timesTmp);
                  await setClinicUpdate(!clinicUpdate);
                }} />
              </div>

              <div className={styles.locationsEntryColumn}>
                {
                  chosenTimes.map((time,timeIndex)=>{
                    return(
                      <div>
                        {(time.location || '').substring(0,24)} {time.date} {time.time} <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let timesTmp = chosenTimes;
                          timesTmp.splice(timeIndex,1);
                          const cookies = new Cookies();
                          const token = await cookies.get('token');
                          await deleteDateTime({token,id:time.id});
                          await setChosenTimes(timesTmp);
                          await setClinicUpdate(!clinicUpdate);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }
              </div>

            </div>
          </div>
        </div>
      )
    }
  }

  const uploadMedicProfileImage = async(file)=>{

    await setLoading(true)
    let formData = new FormData();
    formData.append('file', file)
    formData.append('medicId', editDoctorId)
    const res = await setMedicProfileImageService(formData,token)
    if(res.data.success){
      alert("Profile photo uploaded.")
      setMedicProfileImage(res.data.profile)
      let tmp = medics
      tmp[editDoctorIndex].profile = res.data.profile
      setMedics([...tmp])
    }else{
      alert("Error uploading profile photo.")
    }
    await setLoading(false)
  }

  const renderEditMedic = ()=>{
    if(!editMedicOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditMedicOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Medic Contact</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            <img style={{width:'40px',height:'40px',borderRadius:'50px'}} src={medicProfileImage || require('../../images/medic-profile.png')} />
            <div style={{justifyContent:"flex-end", width:"calc(100% - 5vw)", textAlign:"right", paddingTop:"8vh",paddingLeft:"0vw",paddingRight:"5vw"}}>
              <label for="logoInput">
                <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Profile Image" onClick={(event)=>{}} />
              </label>
            </div>
            <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{
              uploadMedicProfileImage(event.target.files[0])
            }} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />

            <TextInput placeholder="Medic Name*" type="form-text" onChange={(value)=>{setEditDoctorName(value)}} initialValue={editDoctorName}/>
            <TextInput placeholder="Medic Email*" type="form-text" ref={emailRef} onChange={(value)=>{setEditDoctorContactEmail(value)}} initialValue={editDoctorContactEmail}/>
            <TextInput placeholder="Display Email*" type="form-text" ref={emailDisplayRef} onChange={(value)=>{setEditDoctorDisplayEmail(value)}} initialValue={editDoctorDisplayEmail}/>
            <TextInput placeholder="Medic Specialty*" type="form-text" ref={locationRef} onChange={(value)=>{setEditDoctorSpecialty(value)}} initialValue={editDoctorSpecialty}/>
            <TextInput placeholder="Medic Phone*" type="form-text" onChange={(value)=>{setEditDoctorPhone(value)}} initialValue={editDoctorPhone}/>
            <TextInput placeholder="Medic Bio*" type="form-text" onChange={(value)=>{setEditDoctorBio(value)}} initialValue={editDoctorBio}/>
            <TextInput placeholder="Medic Qualifications*" type="form-text" onChange={(value)=>{setEditDoctorQualifications(value)}} initialValue={editDoctorQualifications}/>
            <br/><br/>
            Counties<br/>
            <TextInput placeholder="County*" type="dropdown" options={COUNTIES} onChange={(value)=>{setEditDoctorCountiesText(value)}} initialValue={editDoctorCountiesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = editDoctorCounties;
              clinicsTmp.push(editDoctorCountiesText);
              await setEditDoctorCounties(clinicsTmp);
              await setEditMedicOpen(false);
              await setEditMedicOpen(true);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              editDoctorCounties.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = editDoctorCounties;
                      clinicsTmp.splice(clinicIndex,1);
                      await setEditDoctorCounties(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }

            <br/><br/>
            <input type="checkbox" id="checkbox1" name="checkbox1" checked={editDoctorAnonymous} onChange={(event)=>{setEditDoctorAnonymous(!editDoctorAnonymous)}}></input>
            <span style={{paddingRight:"0px"}}> Is this Medic Anonymous?</span>
            <br/><br/><br/>
            Categories<br/>
            <TextInput placeholder="Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })} onChange={(value)=>{setEditDoctorCategoriesText(value)}} initialValue={editDoctorCategoriesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = editDoctorCategories;
              clinicsTmp.push(editDoctorCategoriesText);
              await setEditDoctorCategories(clinicsTmp);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              editDoctorCategories.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = editDoctorCategories;
                      clinicsTmp.splice(clinicIndex,1);
                      await setEditDoctorCategories(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/>
            Specialties<br/>
            <TextInput placeholder="Specialty*" type="dropdown" options={SPECIALTIES.map((s,si)=>{ return s.name })} onChange={(value)=>{setEditDoctorSpecialtiesText(value)}} initialValue={editDoctorSpecialtiesText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = editDoctorSpecialties;
              clinicsTmp.push(editDoctorSpecialtiesText);
              await setEditDoctorSpecialties(clinicsTmp);
              await setEditMedicOpen(false);
              await setEditMedicOpen(true);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              editDoctorSpecialties.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = editDoctorSpecialties;
                      clinicsTmp.splice(clinicIndex,1);
                      await setEditDoctorSpecialties(clinicsTmp);
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/>
            Clinics<br/>
            <TextInput placeholder="Add Clinic*" type="form-text" onChange={(value)=>{setEditClinicText(value)}} initialValue={editClinicText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Add" onClick={async(event)=>{
              let clinicsTmp = editDoctorClinics;
              clinicsTmp.push(editClinicText);
              await setEditDoctorClinics(clinicsTmp);
              await setDateTimeLocation(clinicsTmp[0] || '');
              await setDateTimeLocationNew(clinicsTmp[0] || '');
              await setDateTimeLocationExisting(clinicsTmp[0] || '');
              await setEditClinicText('');
              await setEditMedicOpen(false);
              await setEditMedicOpen(true);
              await setClinicUpdate(!clinicUpdate);
            }} /><br/><br/>
            {
              editDoctorClinics.map((clinic, clinicIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {clinic}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      let clinicsTmp = editDoctorClinics;
                      clinicsTmp.splice(clinicIndex,1);
                      await setEditDoctorClinics(clinicsTmp);
                      await setDateTimeLocation(clinicsTmp[0] || '');
                      await setDateTimeLocationNew(clinicsTmp[0] || '');
                      await setDateTimeLocationExisting(clinicsTmp[0] || '');
                      await setClinicUpdate(!clinicUpdate);
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/>
            <TextInput placeholder="Internal Note" type="form-textarea" onChange={(value)=>{setEditDoctorInternalNote(value)}} initialValue={editDoctorInternalNote}/>
            <br/><br/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateChosenMedic()}} />
            <TextInput type="submit-input" icon={plusIcon} placeholder="Edit Times" onClick={async(event)=>{
              await setClinicUpdate(!clinicUpdate);
              await setMedicTimesOpen(true);
              await setEditMedicOpen(false);
              await setClinicUpdate(!clinicUpdate);
            }} />
            <TextInput type="submit-input" icon={plusIcon} placeholder="View Times" onClick={async(event)=>{
              await setClinicUpdate(!clinicUpdate);
              await setDoctorDatesOpen(true);
              await setEditMedicOpen(false);
              await setClinicUpdate(!clinicUpdate);
            }} />
          </div>
        </div>
      </div>
    )
  }

  const renderNewSolicitor = ()=>{
    if(!newSolicitorOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewSolicitorOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Solicitor Contact</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            <TextInput placeholder="Solicitor Firm Name*" type="form-text" onChange={(value)=>{setNewSolicitorFirmName(value)}} initialValue={newSolicitorFirmName}/>
            <TextInput placeholder="Solicitor Primary Email*" type="form-text" onChange={(value)=>{setNewSolicitorPrimaryEmail(value)}} initialValue={newSolicitorPrimaryEmail}/>
            <TextInput placeholder="Solicitor Secondary Email*" type="form-text" onChange={(value)=>{setNewSolicitorSecondaryEmail(value)}} initialValue={newSolicitorSecondaryEmail}/>
            <TextInput placeholder="Solicitor Phone*" type="form-text" onChange={(value)=>{setNewSolicitorPhone(value)}} initialValue={newSolicitorPhone}/>
            <TextInput placeholder="Solicitor Address*" type="form-text" onChange={(value)=>{setNewSolicitorAddress(value)}} initialValue={newSolicitorAddress}/>
            <TextInput placeholder="Internal Note" type="form-textarea" onChange={(value)=>{setNewSolicitorInternalNote(value)}} initialValue={newSolicitorInternalNote}/>
            <br/><br/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{createSolicitor()}} />

          </div>
        </div>
      </div>
    )
  }

  const renderEditSolicitor = ()=>{
    if(!editSolicitorOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditSolicitorOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Solicitor Contact</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            <TextInput placeholder="Solicitor Firm Name*" type="form-text" onChange={(value)=>{setEditSolicitorFirmName(value)}} initialValue={editSolicitorFirmName}/>
            <TextInput placeholder="Solicitor Primary Email*" type="form-text" onChange={(value)=>{setEditSolicitorPrimaryEmail(value)}} initialValue={editSolicitorPrimaryEmail}/>
            <TextInput placeholder="Solicitor Secondary Email*" type="form-text" onChange={(value)=>{setEditSolicitorSecondaryEmail(value)}} initialValue={editSolicitorSecondaryEmail}/>
            <TextInput placeholder="Solicitor Phone*" type="form-text" onChange={(value)=>{setEditSolicitorPhone(value)}} initialValue={editSolicitorPhone}/>
            <TextInput placeholder="Solicitor Address*" type="form-text" onChange={(value)=>{setEditSolicitorAddress(value)}} initialValue={editSolicitorAddress}/>
            <TextInput placeholder="Internal Note" type="form-textarea" onChange={(value)=>{setEditSolicitorInternalNote(value)}} initialValue={editSolicitorInternalNote}/>
            <br/><br/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateChosenSolicitor()}} />

          </div>
        </div>
      </div>
    )
  }

  const renderMedicTypes = ()=>{
    if(!medicTypesOpen) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setMedicTypesOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Medic Categories & Specialties</div>
            </div>
          </div>
          <div className={styles.ordersForm}>
            Categories: <br/><br/>
            <TextInput placeholder="Category Name" type="form-text" onChange={(value)=>{setNewCategoryText(value)}} initialValue={newCategoryText}/>
            <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={async(event)=>{
              const cookies = new Cookies();
              const token = await cookies.get('token');
              await setLoading(true)
              const createRes = await updateMedicTypes({token,createCategories:[{name:newCategoryText}]})
              if(createRes.data.success){
                setCategories([...CATEGORIES,...createRes.data.newCategories])
              }else{
                alert("Error. Please try again.")
              }
              await setLoading(false)
            }} />
            <br/>
            {
              CATEGORIES.map((category, categoryIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {category.name}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      const cookies = new Cookies();
                      const token = await cookies.get('token');
                      await setLoading(true)
                      const deleteRes = await updateMedicTypes({token,deleteCategories:[category]})
                      if(deleteRes.data.success){
                        let tmp = CATEGORIES
                        tmp.splice(categoryIndex,1)
                        await setCategories([...tmp])
                      }else{
                        alert("Error. Please try again.")
                      }
                      await setLoading(false)
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
            <br/><br/><br/>
            Specialties: <br/><br/>
            <TextInput placeholder="Specialty Name" type="form-text" onChange={(value)=>{setNewSpecialtyText(value)}} initialValue={newSpecialtyText}/>
            <TextInput placeholder="Category" type="object-dropdown" options={CATEGORIES} identifier={'id'} display={'name'} onChange={(value)=>{setNewSpecialtyCategory(value)}} initialValue={newSpecialtyCategory}/>

            <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={async(event)=>{
              const cookies = new Cookies();
              const token = await cookies.get('token');
              await setLoading(true)
              const createRes = await updateMedicTypes({token,createSpecialties:[{name:newSpecialtyText,categoryId:newSpecialtyCategory}]})
              if(createRes.data.success){
                setSpecialties([...SPECIALTIES,...createRes.data.newSpecialties])
              }else{
                alert("Error. Please try again.")
              }
              await setLoading(false)
            }} />
            {
              SPECIALTIES.map((specialty, specialtyIndex)=>{
                return(
                  <div style={{paddingTop:"10px"}}>
                    {specialty.name} Category: {CATEGORIES.filter((c,ci)=>{ return specialty.categoryId == (c.id) }).map((c,ci)=>{ return c.name })}
                    <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                      const cookies = new Cookies();
                      const token = await cookies.get('token');
                      await setLoading(true)
                      const deleteRes = await updateMedicTypes({token,deleteSpecialties:[specialty]})
                      if(deleteRes.data.success){
                        let tmp = SPECIALTIES
                        tmp.splice(specialtyIndex,1)
                        await setSpecialties([...tmp])
                      }else{
                        alert("Error. Please try again.")
                      }
                      await setLoading(false)
                    }} className={styles.removeFileButton} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewMedic()}
        {renderNewSolicitor()}
        {renderEditMedic()}
        {renderMedicTimes()}
        {renderDoctorDates()}
        {renderRecord()}
        {renderEditSolicitor()}
        {renderMedicTypes()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Contact"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.NAME+" "+user.SURNAME || "Aalto User"}</div>
              </div>


              <div className="global-header-buttons">
              <TextInput type="submit-input" icon={plusIcon} placeholder="Medic Types" onClick={async(event)=>{
                setMedicTypesOpen(true)
              }} />
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"10px", marginRight:"10px", height:"85vh",overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                    Medic Contacts
                    <div style={{flex:1,textAlign:'right',verticalAlign:"middle",display:"inline-block",alignItems:'flex-end'}}>
                      <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{setNewMedicOpen(true)}} />
                    </div>
                    <TextInput small={true} placeholder="Search" noHeader={true} type="form-text" onChange={(value)=>{setMedicSearch(value)}} initialValue={medicSearch}/>

                  </div>
                  <table>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Name</th>
                      <th className={styles.productsResultHeaderItem}>Email</th>
                    </tr>
                    {
                      medics.filter((record)=>{
                        return  (record.name) && !(medicSearch.length > 0 && !medicSearch.toUpperCase().includes(record.name.toUpperCase()) && !record.name.toUpperCase().includes(medicSearch.toUpperCase()))
                      }).map((record, recordIndex)=>{

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} onClick={async(event)=>{
                              await setDoctorDates(record.ml_med_dates || []);
                              await setEditDoctorId(record.id);
                              await setEditDoctorIndex(recordIndex);
                              await setEditDoctorName(record.name);
                              await setMedicProfileImage(record.profile);
                              await setChosenTimes(record.ml_med_dates || []);
                              await setEditDoctorPhone(record.phone);
                              await setEditDoctorClinics(record.clinics.split('[****SEP****]'));
                              await setDateTimeLocation(record.clinics.split('[****SEP****]')[0] || '');
                              await setDateTimeLocationNew(record.clinics.split('[****SEP****]')[0] || '');
                              await setDateTimeLocationExisting(record.clinics.split('[****SEP****]')[0] || '');
                              await setEditDoctorSpecialty(record.specialty);
                              await setEditDoctorContactEmail(record.contactEmail);
                              await setEditDoctorDisplayEmail(record.displayEmail || '');
                              await setEditDoctorInternalNote(record.internalNote);
                              await setEditClinicText('');
                              await setEditDoctorSpecialties((record.specialties || '').split('[****SEP****]'))
                              await setEditDoctorCategories((record.categories || '').split('[****SEP****]'))
                              await setEditDoctorCounties((record.county || '').split('[****SEP****]'))
                              await setEditDoctorBio(record.bio || '');
                              await setEditDoctorQualifications(record.qualifications || '');
                              await setEditDoctorAnonymous(record.anonymous || 0);
                              await setEditMedicOpen(true);
                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.name}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.contactEmail}</td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} onClick={async(event)=>{
                            await setDoctorDates(record.ml_med_dates || []);
                            await setEditDoctorId(record.id);
                            await setEditDoctorIndex(recordIndex);
                            await setChosenTimes(record.ml_med_dates || []);
                            await setMedicProfileImage(record.profile);
                            await setEditDoctorName(record.name);
                            await setEditDoctorPhone(record.phone);
                            await setEditDoctorClinics(record.clinics.split('[****SEP****]'));
                            await setDateTimeLocation(record.clinics.split('[****SEP****]')[0] || '');
                            await setDateTimeLocationNew(record.clinics.split('[****SEP****]')[0] || '');
                            await setDateTimeLocationExisting(record.clinics.split('[****SEP****]')[0] || '');
                            await setEditDoctorSpecialty(record.specialty);
                            await setEditDoctorContactEmail(record.contactEmail);
                            await setEditDoctorDisplayEmail(record.displayEmail);
                            await setEditDoctorInternalNote(record.internalNote);
                            await setEditDoctorSpecialties((record.specialties || '').split('[****SEP****]'))
                            await setEditDoctorCategories((record.categories || '').split('[****SEP****]'))
                            await setEditDoctorCounties((record.county || '').split('[****SEP****]'))
                            await setEditClinicText('');
                            await setEditDoctorBio(record.bio || '');
                            await setEditDoctorQualifications(record.qualifications || '');
                            await setEditDoctorAnonymous(record.anonymous || 0);
                            await setEditMedicOpen(true);
                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.name}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.contactEmail}</td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>

                <div style={{marginLeft:"10px", marginRight:"10px", height:"85vh",overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                    Solicitor Contacts
                    <div style={{flex:1,textAlign:'right',verticalAlign:"middle",display:"inline-block",alignItems:'flex-end'}}>
                      <TextInput type="submit-input" icon={plusIcon} placeholder="Create" onClick={(event)=>{setNewSolicitorOpen(true)}} />
                    </div>
                    <TextInput small={true} placeholder="Search" noHeader={true} type="form-text" onChange={(value)=>{setSolicitorSearch(value)}} initialValue={solicitorSearch}/>

                  </div>
                  <table>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Name</th>
                      <th className={styles.productsResultHeaderItem}>Email</th>
                    </tr>
                    {
                      solicitors.filter((record)=>{
                        return  (record.firmName) && !(solicitorSearch.length > 0 && !solicitorSearch.toUpperCase().includes(record.firmName.toUpperCase()) && !record.firmName.toUpperCase().includes(solicitorSearch.toUpperCase()))
                      }).map((record, recordIndex)=>{

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} onClick={async(event)=>{
                              await setEditSolicitorId(record.id);
                              await setEditSolicitorIndex(recordIndex);
                              await setEditSolicitorFirmName(record.firmName);
                              await setEditSolicitorInternalNote(record.internalNote);
                              await setEditSolicitorPhone(record.phone);
                              await setEditSolicitorAddress(record.address);
                              await setEditSolicitorPrimaryEmail(record.primaryEmail);
                              await setEditSolicitorSecondaryEmail(record.secondaryEmail);
                              await setEditSolicitorReference(record.reference);
                              await setEditSolicitorOpen(true);
                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.firmName}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.primaryEmail}</td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} onClick={async(event)=>{
                            await setEditSolicitorId(record.id);
                            await setEditSolicitorIndex(recordIndex);
                            await setEditSolicitorFirmName(record.firmName);
                            await setEditSolicitorInternalNote(record.internalNote);
                            await setEditSolicitorPhone(record.phone);
                            await setEditSolicitorAddress(record.address);
                            await setEditSolicitorPrimaryEmail(record.primaryEmail);
                            await setEditSolicitorSecondaryEmail(record.secondaryEmail);
                            await setEditSolicitorReference(record.reference);
                            await setEditSolicitorOpen(true);
                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.firmName}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.primaryEmail}</td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
