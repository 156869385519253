import React from 'react';

// Styles
import styles from './index.module.scss';
// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { withAlert } from 'react-alert';
import Cookies from 'universal-cookie';

// Assets
import arrowForward from '../../images/arrow-forward.png';

export const SideMenuItem = ({href,icon,text,isActive})=>{
  return(
    <a href={href}>
      <div className={isActive?styles.sideMenuItemMainActive:styles.sideMenuItemMain}>
        <div className={isActive?styles.sideMenuItemMainBarActive:styles.sideMenuItemMainBar}></div>
        <img src={icon} className={styles.sideMenuItemIcon} />
        <div className={styles.sideMenuItemTextMain}>{text}</div>
      </div>
    </a>
  )
}
